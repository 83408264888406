import { api_wrapper } from "../../services/api_wrapper.js";

export var get_requests = api_wrapper(`
query (@autodeclare) {
  missions(first: $perpage, after: $cursor){
    edges{
      node{
        id
        name
        latestRunTime
        missionType
        timestampRange
        status
        created
        simrunSet(first: 500){
          edges{
            node{
              id
              snapTypeLabel
              created
              vehicles
              targets
            }
          }
        }
      }
    }

    totalCount
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`);

export var get_cogs = api_wrapper(`
query ($perpage: Int, $cursor: String, $sort: String, $search: String) {
  missions(first: $perpage, after: $cursor, sortBy: $sort, search: $search){
    edges{
      node{
        id
        name
        extid
        latestRunTime
        constellationCount
        sensorCount
        targetCount
        missionType
        timestampRange
        status
        created
        simrunSet(first: 500){
          edges{
            node{
              id
              name
              snapTypeLabel
              created
              vehicles
              targets
            }
          }
        }
        baseMission{
          id
          name
        }
      }
    }

    totalCount
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`);

export var get_simrun = api_wrapper(`
query ($mission: ID, $id: ID) {
  simRuns(first: 10, mission: $mission, id: $id) {
    edges {
      node {
        id
        name
        vehicles
        customerTargets
        locked
        created
        scenario
        mission{
          id
          name
          missionType
          targets
          sensorIds
          timestampRange
          simrunSet(first: 500) {
            edges{
              node{
                name
                id
                scenario
              }
            }
          }
        }
        simfileSet (first:500) {
          edges{
            node{
              filename
              attr
              extension
              url
            }
          }
        }
      }
    }
  }
}
`);

export var get_tip_cue = api_wrapper(
  `
query ($tip_mission: ID) {
  queueOpportunities(first: 500, tip_Simrun_Mission: $tip_mission) {
    edges {
      node {
        id
        tip {
          id
          collectEnd
          collectStart
          sensorName
          providerName
          sensor {
            name
            catalogId
            sensorType
            constellation {
              name
              id
            }
          }
        }
        queue {
          id
          collectEnd
          collectStart
          sensorName
          providerName
          sensor {
            name
            catalogId
            sensorType
            constellation {
              name
              id        
            }
          }
        }
      }
    }
  }
}
`
);

export var opportunities = api_wrapper(
  `
query ($mission: ID, $simrun: ID) {
  opportunities(first: 5000, simRun_Mission: $mission, simRun: $simrun) {
    edges {
      node {
        id
        rawId
        collectStart
        collectEnd
        rawdata
        concurrentCount
        isScheduled
        sensor {
          name
          sensorType
        }
        providerName
        simTargetId
        catalogId
        coordinates
        sunAzimuth
        sunElevation
        target{
          name
        }
        simRun{
          id
          scenario
        }
      }
    }
  }
}
`,
  "opportunities"
);

export var get_concurrent_opportunities = api_wrapper(
  `
query ($mission: ID) {
  opportunities(first: 5000, simRun_Mission: $mission, hasConcurrent: true) {
    edges {
      node {
        id
        collectStart
        collectEnd
        providerName
        concurrentCount
        sensor {
          name
          sensorType
        }
        catalogId
        target{
          name
        }
      }
    }
  }
}
`
);

export var get_concurrent_details = api_wrapper(
  `
query ($id: ID) {
  opportunities(first: 1, id: $id) {
    edges {
      node {
        id  
        concurrents(first: 500) {
          edges{
            node{
              id
              catalogId
              collectStart
              collectEnd
              providerName
              sensor {
                name
                sensorType
              }
            }
          }
        }
      }
    }
  }
}
`
);

export var get_scheduled_opportunities = api_wrapper(`
query ($parentId: ID){
  scheduledOpportunities(first:100, parent:$parentId){
    edges{
      node{
        id
        collectStart
        collectEnd
      }
    }
  }
}
`);

export var get_sim_schedule = api_wrapper(`
query ($simrun: String) {
  scheduledOpportunities(first: 500, simrun: $simrun) {
    edges {
      node {
        id
        isScheduled
        rawdata
        collectStart
        target{
          name
        }
        providerName
        sensor {
          id
          name
          sensorType
        }
        simRun {
          id
          name
        }
      }
    }
  }
}
`);

export var run_mission = api_wrapper(`
mutation (@autodeclare) {
  runMission(input: {mission: $mission}) {
    sims
    errors{
      messages
    }
  }
}
`);

export var pstats = api_wrapper(
  `
query (@autodeclare) {
  simRuns(first: 500, mission: $id) {
    edges{
      node{
        id
        targets
        vehicles
        created
        priorityStats
        runtime
      }
    }
  }
}
`,
  "pstats"
);

export var save_cog = api_wrapper(`
mutation (
    $id: ID
    $name: String!
    $targets: GenericScalar!
    $sensors: [ID]!
    $start: DateTime
    $end: DateTime
    $missionType: String!
    $concurrentTimeRange: Float!
    $concurrentProviders: [ID]!
  ) {
  saveMission(input: {
    id: $id
    name: $name
    targets: $targets
    sensors: $sensors
    start: $start
    end: $end
    missionType: $missionType
    concurrentTimeRange: $concurrentTimeRange
    concurrentProviders: $concurrentProviders
  }) {
    mission {
      id
    }
    errors{
      messages
    }
  }
}
`);

export var get_cog = api_wrapper(`
query (@autodeclare) {
  getMission(id: $id) {
    id
    name
    start
    end
    targets
    missionType
    status
    sensorIds
    concurrentTimeRange
    concurrentProviderIds
    simrunSet(first: 500){
      edges{
        node{
          id
          name
          snapTypeLabel
          created
          vehicles
          targets
        }
      }
    }

  }
}
`);
export var get_latest_cogs = api_wrapper(`
query {
  latestBaseMissions{
        id
        name
        extid
        latestRunTime
        constellationCount
        sensorCount
        targetCount
        missionType
        timestampRange
        status
        created
        targets
        simrunSet(first: 500){
          edges{
            node{
              id
              name
              snapTypeLabel
              created
              vehicles
              targets
            }
          }
        }
        baseMission{
          id
          name
        }
  }
}
`);

export var get_constellations = api_wrapper(`
query (@autodeclare) {
  constellations(first: 100) {
    edges {
      node {
        id
        name
        sensorSet(first:500) {
          edges {
            node {
              id
              sensorType
              name
              catalogId
              isTaskable
              isAvailable
            }
          }
        }
      }
    }
  }
  providers(first: 100){
    edges{
      node{
        id
        name
      }
    }
  }
}
`);
