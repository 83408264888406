export var loadingMixin = {
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    display() {
      if (this.$store.state.loading) {
        return { display: "block" };
      }

      return { display: "none" };
    }
  }
};

export var initMixin = {
  mounted() {
    if (this.init) {
      this.init();
    }
  },
  watch: {
    /* eslint-disable-next-line no-unused-vars */
    $route(to, from) {
      if (this.init) {
        this.init();
      }
    }
  }
};

export var DisplayMixin = {
  methods: {
    get_id(item) {
      return atob(item.id).split(":")[1];
    }
  }
};
