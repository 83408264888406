import graphql from "graphql.js";
import { alert_dialog } from "../widgets/dialogs.js";
import store from "../store/index.js";
import { flatten } from "./tools.js";

export function api_wrapper2(gql) {
  return function() {
    const API_URL = process.env.API_URL || "/graphql";

    const headers = {};

    if (store.state.token) {
      headers["Authorization"] = `Bearer ${store.state.token}`;
    }

    const graphqlConnection = graphql(API_URL, { headers });

    let apiCall;
    if (gql.trim().startsWith("mutation")) {
      apiCall = graphqlConnection.mutate(gql);
    } else {
      apiCall = graphqlConnection.query(gql);
    }

    return apiCall();
  };
}

export function api_wrapper(gql) {
  return function({ inputs, token, callback, error_callback }) {
    //store.commit("set_loading", true);
    var api_call;
    var headers = {};
    var API_URL = process.env.API_URL || "/graphql";

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    } else if (store.state.token) {
      headers["Authorization"] = `Bearer ${store.state.token}`;
    }

    var data_graph = graphql(API_URL, { headers });
    if (gql.trim().startsWith("mutation")) {
      api_call = data_graph.mutate(gql);
    } else {
      api_call = data_graph.query(gql);
    }

    api_call(inputs)
      .then(results => {
        for (var e in results) {
          var errors = results[e].errors;
          if (errors && errors.length > 0) {
            var message = errors[0].messages[0];
            console.error(message);
            alert_dialog("Error", message, error_callback);
            return;
          }
        }

        if (callback) {
          flatten(results);
          callback(results);
        }
      })
      .catch(e => {
        console.error(e);
        const error = Array.isArray(e) ? e[0].message : e.errors[0].message;
        alert_dialog("Error", error, error_callback);
      });
  };
}
