import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

var webgl = false;
var canvas = document.createElement("canvas");

if (!window.SkipGL) {
  var gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  if (gl && gl instanceof WebGLRenderingContext) {
    webgl = true;
  }
}

export function generate_store() {
  return new Vuex.Store({
    state: {
      dialog: null,
      snack: null,
      logged_in: false,
      login_expires: 0,
      loading: false,
      window_width: 0,
      window_height: 0,
      timezone: localStorage.timezone || "Europe/London",
      oppupdate: null,
      allow_nav: true,
      webgl: webgl,
      token: null,
      modules: null,
      license_expires: 0,
      org_logo: null
    },
    mutations: {
      login(state, d) {
        state.logged_in = d.authed;
        state.login_expires = d.expiration;
      },
      set_loading(state, d) {
        state.loading = d;
      },
      set_token(state, d) {
        state.token = d;
      },
      set_nav(state, d) {
        state.allow_nav = d;
      },
      set_dialog(state, d) {
        state.dialog = d;
      },
      set_snack(state, d) {
        state.snack = d;
      },
      set_window(state, d) {
        state.window_width = d.width;
        state.window_height = d.height;
      },
      set_timezone(state, tz) {
        state.timezone = tz;
        localStorage.timezone = tz;
      },
      set_oppupdate(state, dt) {
        state.oppupdate = dt;
      },
      set_modules(state, d) {
        state.modules = d.modules;
        state.license_expires = d.expires;
      },
      set_logo(state, l) {
        state.org_logo = l;
      }
    }
  });
}

var store = generate_store();
export default store;
