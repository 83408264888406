import Vue from "vue";
import { Settings } from "luxon";

import Vuetify from "vuetify";
import VueRouter from "vue-router";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import DatetimePicker from "vuetify-datetime-picker";

import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration } from "@sentry/integrations";

import Chartkick from "vue-chartkick";
import Highcharts from "highcharts";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import store from "./store/index.js";
import router from "./routing.js";
import mainApp from "./main.vue";

import "vuetify/dist/vuetify.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mdi/font/css/materialdesignicons.css";
import "typeface-roboto/index.css";
import "./sc3.less";
import { install_filters } from "./services/filters.js";
import colors from "vuetify/lib/util/colors";

Settings.defaultZoneName = "utc";

window.mapboxgl = mapboxgl;
window.MapboxDraw = MapboxDraw;

if (!SETTINGS.debug) {
  Sentry.init({
    Vue,
    dsn: "https://6bfa9eba75a84210861cdf2b35fa0d3a@sentry.cntient.space/3",
    integrations: []
  });
}

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(vueNumeralFilterInstaller);
Vue.use(DatetimePicker);
Vue.use(Chartkick.use(Highcharts));

install_filters(Vue);

Vue.prototype.$settings = SETTINGS;

if (SETTINGS.debug) {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}

var app = new Vue({
  el: "#app",
  vuetify: new Vuetify({
    theme: {
      dark: true,
      themes: {
        dark: {
          primary: colors.blueGrey.darken3, //#37474F
          secondary: colors.blueGrey.lighten1, //#78909C
          accent: colors.cyan.darken1, //#00ACC1
          error: colors.pink.accent3, //#F50057,
          info: colors.blueGrey.darken1, //#546E7A
          success: colors.green.lighten1, //#66BB6A
          warning: colors.orange.lighten2 //#FFB74D
        }
      }
    }
  }),
  router: router,
  store: store,
  components: { mainApp }
});
