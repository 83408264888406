<template>
  <v-app class="app-inner">
    <v-app-bar color="primary" app dense>
      <v-app-bar-nav-icon
        v-if="authed"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <!-- <img
            v-if="logoUrl"
            :src="logoUrl"
            :alt="orgName"
            style="height: 42px"
          /> -->
          <span v-if="orgName">{{ orgName }}</span>
          <span v-else>CNTIENT-SPACE</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="authed" class="app-buttons">
        <set-timezone></set-timezone>
        <v-btn href="/graphql" small icon target="_blank">
          <v-icon>mdi-api</v-icon>
        </v-btn>
        <v-btn to="/accounts/edit" v-if="authed" small icon>
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn href="/accounts/logout/" v-if="authed" small icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="allow_nav"
      v-model="drawer"
      app
      mobile-breakpoint="1800"
    >
      <v-list dense class="nav-list">
        <v-list-group
          v-if="modules && modules.includes('space')"
          :value="false"
          color="accent"
        >
          <template v-slot:activator>
            <v-list-item-title>CNTIENT-SPACE&trade;</v-list-item-title>
          </template>

          <v-list-item to="/missions">
            <v-list-item-action>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Missions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="modules && modules.includes('ground')"
          :value="false"
          color="accent"
        >
          <template v-slot:activator>
            <v-list-item-title>CNTIENT-GROUND&trade;</v-list-item-title>
          </template>

          <v-list-item to="/ground/services/list">
            <v-list-item-action>
              <v-icon>mdi-hammer-wrench</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ground Services</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ground/stations/list">
            <v-list-item-action>
              <v-icon>mdi-satellite-uplink</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ground Stations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ground/satellites/list">
            <v-list-item-action>
              <v-icon>mdi-satellite-variant</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Satellites</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ground/passes/list">
            <v-list-item-action>
              <v-icon>mdi-access-point</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Passes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ground/passes/schedule">
            <v-list-item-action>
              <v-icon>mdi-earth-plus</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Schedule a Pass</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item to="/ground/passes/passTracking">
            <v-list-item-action>
              <v-icon>mdi-radar</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Tracking</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item to="/ground/passes/availabilityTimeline">
            <v-list-item-action>
              <v-icon>mdi-chart-bar-stacked</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Availability Timeline</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>

        <v-list-group
          v-if="modules && modules.includes('sites')"
          :value="false"
          color="accent"
        >
          <template v-slot:activator>
            <v-list-item-title>SITES&trade;</v-list-item-title>
          </template>

          <v-list-item to="/sites/sites-map">
            <v-list-item-action>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sites Map</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/sites/sets">
            <v-list-item-action>
              <v-icon>mdi-target-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Site Sets</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="modules && modules.includes('forecast')"
          :value="false"
          color="accent"
        >
          <template v-slot:activator>
            <v-list-item-title>CNTIENT-SCALE&trade;</v-list-item-title>
          </template>

          <v-list-item to="/scale/study/add">
            <v-list-item-action>
              <v-icon>mdi-hammer-wrench</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Build Study</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/scale/study/list">
            <v-list-item-action>
              <v-icon>mdi-web</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Studies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/scale/constellation/list">
            <v-list-item-action>
              <v-icon>mdi-satellite-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Constellations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/scale/customer/list">
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/sites/sets">
            <v-list-item-action>
              <v-icon>mdi-map-marker-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Manage Sites</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider dark></v-divider>

        <v-list-item color="accent" to="/">
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="accent" to="/accounts/edit" v-if="authed">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>My Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="accent" to="/org/settings" v-if="authed">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Organization Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="accent" href="/accounts/logout/" v-if="authed">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-footer absolute>
        Built by:&nbsp;
        <a
          class="accent-a"
          href="https://www.cognitivespace.com/"
          target="_blank"
          >Cognitive Space</a
        >
      </v-footer>
    </v-navigation-drawer>
    <v-main class="main-content">
      <v-container class="main-container">
        <component v-bind:is="dialog"></component>
        <component v-bind:is="snack"></component>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import debounce from "debounce";

import { initMixin } from "./mixins/index.js";
import { get_license_org_settings } from "./core/config/api.js";
import SetTimezone from "./widgets/set_timezone.vue";
import "regenerator-runtime/runtime";

export default {
  mixins: [initMixin],
  components: { SetTimezone },
  data() {
    return { drawer: null, orgName: null };
  },
  computed: {
    authed() {
      return this.$store.state.logged_in;
    },
    dialog() {
      return this.$store.state.dialog;
    },
    snack() {
      return this.$store.state.snack;
    },
    allow_nav() {
      return this.$store.state.allow_nav;
    },
    modules() {
      return this.$store.state.modules;
    },
    logoUrl() {
      return this.$store.state.org_logo;
    }
  },
  mounted() {
    this.resize();
    var rfunc = debounce(() => {
      this.resize();
    }, 200);
    window.addEventListener("resize", rfunc);
  },
  methods: {
    init() {
      get_license_org_settings({
        callback: result => {
          //REMOVE SITES FROM MODULES UNTIL READY TO BE CUSTOMER-FACING
          let sitesIndex = result.license.modules.indexOf("sites");
          if (sitesIndex != -1) {
            result.license.modules.splice(sitesIndex, 1);
          }

          this.$store.commit("set_modules", result.license);

          if (result.orgSettings.flat.length > 0) {
            this.orgName = result.orgSettings.flat[0].name;
            this.$store.commit("set_logo", result.orgSettings.flat[0].logoUrl);
            if (this.orgName) {
              document.title = "CNTIENT Space | " + this.orgName;
            }
          }
        }
      });
    },
    resize() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      var height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.$store.commit("set_window", { width, height });
    }
  }
};
</script>
<style lang="less">
.app-buttons {
  display: flex;
  align-items: center;

  a.v-btn {
    margin: 0 5px;
    display: inline-block;
  }
}

.v-toolbar__title {
  a {
    color: white !important;
    text-decoration: none;
    font-size: 90%;
    display: flex;
    align-items: center;

    img {
      padding-right: 8px;
    }
  }
}
</style>
