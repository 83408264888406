<template>
  <div class="loader grey darken-4">
    <v-row justify="center">
      <v-progress-circular
        :size="75"
        width="3"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row justify="center">
      <h3 class="message">{{ loadingMessage }}</h3>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: ["loadingMessage"]
};
</script>
<style lang="less">
.loader {
  padding: 20%;
}
.message {
  margin-top: 10%;
}
</style>
