<template>
  <v-sheet class="targets ml-2 mr-2 mb-5">
    <component
      :is="targetEdit"
      :isActive.sync="targetEdit"
      v-bind="rprops"
      @commit="pushTarget"
    ></component>
    <v-row>
      <v-col>
        <h2>Targets</h2>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col>
        <div class="buttons-group d-flex">
          <v-btn color="accent" small @click="addTarget()" class="mr-2">
            <v-icon small>mdi-plus</v-icon> Add
          </v-btn>
          <v-btn color="accent" small @click="uploadTarget()">
            <v-icon small>mdi-upload</v-icon> Upload
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="application/json"
            @change="onFileChange"
          />
        </div>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-btn color="secondary" small @click="syncTimes()"
          >Set Times for All Targets</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Target</th>
                <th class="text-left">Type</th>
                <th class="text-left">Geometry</th>
                <th class="text-left">Priority</th>
                <th class="text-left">Start (UTC)</th>
                <th class="text-left">End (UTC)</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="targets.length > 0">
              <tr v-for="(r, index) in targets" :key="r.id">
                <td>{{ r.name }}</td>
                <td>
                  <v-icon v-if="r.isMobile" title="Mobile">mdi-target</v-icon>
                  <v-icon v-else title="Stationary">mdi-pin</v-icon>
                </td>
                <td>{{ r.targetType }}</td>
                <td>{{ pname(r.priority) }}</td>
                <td>{{ r.start | dt }}</td>
                <td>{{ r.end | dt }}</td>
                <td>
                  <div class="d-flex flex-row justify-end">
                    <v-btn small icon @click="editTarget(index, r)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small icon @click="removeTarget(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <div class="d-flex justify-center">
                    <v-label>There are no targets.</v-label>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  alert_dialog,
  confirm_dialog,
  snack
} from "../../../widgets/dialogs.js";
import { replace_zone } from "../../../services/tools.js";

import TargetEdit from "./TargetEdit.vue";

function processFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = fileEvent => {
      const fileContents = fileEvent.target.result;
      resolve(fileContents);
    };
    reader.onerror = () => {
      reject("There was an error.");
    };
    reader.readAsText(file);
  });
}

function onFileChange(e) {
  let file = e.target.files[0];
  let fileType = file.type;
  if (fileType == "application/json") {
    this.processFile(file).then(fileContent => {
      let fileJSON = JSON.parse(fileContent);
      if (fileJSON.targets) {
        fileJSON.targets.forEach((target, index) => {
          let targetObj = {};
          let start;
          let stop;
          if (target.time_min && target.time_max) {
            start = new Date(`${target.time_min} UTC`);
            stop = new Date(`${target.time_max} UTC`);
          } else {
            start = null;
            stop = null;
          }

          targetObj.name = target.name ? target.name : `Target ${index + 1}`;
          targetObj.start = start;
          targetObj.end = stop;
          targetObj.priority = target.priority;
          if (Array.isArray(target.lat_lon_alt[0])) {
            targetObj.targetType = "Polygon";
            target.lat_lon_alt.forEach(coords => {
              let lat = coords[0];
              let long = coords[1];
              coords[0] = long;
              coords[1] = lat;
              coords.pop();
            });
          } else {
            targetObj.targetType = "Point";
            let lat = target.lat_lon_alt[0];
            let long = target.lat_lon_alt[1];
            target.lat_lon_alt[0] = long;
            target.lat_lon_alt[1] = lat;
            target.lat_lon_alt.pop();
          }
          targetObj.coordinates = target.lat_lon_alt;
          this.targets.push(targetObj);
        });
      } else {
        snack(
          'Incorrect file structure. Please ensure that your data object contains a "targets" property.'
        );
      }
    });
  } else {
    snack("Incorrect file format. Please upload a .json or .geojson file.");
  }
}

export default {
  props: ["targets", "missionType", "start", "end"],
  components: { TargetEdit },
  data() {
    return {
      targetEdit: null,
      targetIndex: null,
      currentTarget: null,
      selectedTargetFile: null,
      isDialogOpen: false
    };
  },
  computed: {
    rprops() {
      var props = {
        target: this.currentTarget,
        missionType: this.missionType,
        time: [this.start, this.end],
        index: this.targetIndex
      };
      return props;
    }
  },
  methods: {
    processFile: processFile,
    onFileChange: onFileChange,
    syncTimes() {
      this.targets.forEach(t => {
        t.start = this.start;
        t.end = this.end;
      });
    },
    pname(level) {
      if (level == 1) {
        return "High";
      } else if (level == 2) {
        return "Normal";
      }
      return "Low";
    },
    addTarget() {
      this.currentTarget = null;
      this.targetIndex = null;
      this.targetEdit = TargetEdit;
    },
    uploadTarget() {
      this.$refs.uploader.click();
    },
    editTarget(index, target) {
      this.currentTarget = target;
      this.targetIndex = index;
      this.targetEdit = TargetEdit;
    },
    removeTarget(index) {
      confirm_dialog("Are you sure you wish to delete this target?")
        .then(result => {
          this.targets.splice(index, 1);
        })
        .catch(e => {});
    },
    pushTarget(index, target) {
      if (target.targetType == "Circle") {
        target.targetType = "Polygon";
      }
      target.start = replace_zone(target.start);
      target.end = replace_zone(target.end);

      if (index === null) {
        this.targets.push(target);
      } else {
        this.targets.splice(index, 1, target);
      }
      this.$emit("update:targets", this.targets);
    }
  }
};
</script>
<style lang="less">
.targets {
  width: 100%;
  .buttons-group {
    width: 33%;
  }
}
</style>
