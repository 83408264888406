<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <v-card>
      <v-card-title primary-title>
        Confirmation
      </v-card-title>
      <v-card-text>
        <br />
        <h2>{{ message }}</h2>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="yes()">
          <v-icon>mdi-check</v-icon>
          Yes
        </v-btn>
        <v-btn @click="no()">
          <v-icon>mdi-close</v-icon>
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return { dialog: true };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$store.commit("set_dialog", null);
    },
    yes() {
      this.close();
      this.resolve(true);
    },
    no() {
      this.close();
      this.reject(false);
    }
  }
};
</script>
