import { api_wrapper } from "../../services/api_wrapper.js";

export var get_license_org_settings = api_wrapper(`
query {
  orgSettings(first: 1){
    edges {
      node{
        name
        logoUrl
      }
    }
  }
  license{
    modules
    expires
  }
}
`);

export function save_org_settings(inputs) {
  var formData = new FormData();
  var operations = {
    query: `mutation ($logo: Upload, $name: String) {
      saveOrgSettings(input: {
        logo: $logo
        name: $name
      }) {
        success
      }
    }`,
    variables: {
      logo: null,
      name: inputs.name
    }
  };
  var map = {};
  if (inputs.logo) {
    map = { "0": ["variables.logo"] };
  }

  formData.append("operations", JSON.stringify(operations));
  formData.append("map", JSON.stringify(map));
  if (inputs.logo) {
    formData.append(0, inputs.logo);
  }

  return fetch("/graphql", {
    method: "POST",
    body: formData
  });
}
