import { api_wrapper } from "../../services/api_wrapper.js";

export var login = api_wrapper(`
mutation (@autodeclare) {
  login(
    input: {
      username: $username
      password: $password
    }
  ) {
    user{
      username
      sessionExpiration
    }
    errors{
      messages
    }
  }
}
`);

export var complete_invite = api_wrapper(`
mutation (@autodeclare) {
  completeInvite(
    input: {
      username: $username
      password: $password
      email: $email
      firstName: $firstName
      lastName: $lastName
      token: $token
    }
  ) {
    user{
      username
      sessionExpiration
    }
    errors{
      messages
    }
  }
}
`);

export var change_password = api_wrapper(`
mutation (@autodeclare) {
  changePassword(
    input: {
      password: $password
    }
  ) {
    user{
      username
      sessionExpiration
    }
    errors{
      messages
    }
  }
}
`);

export var get_profile = api_wrapper(`
query{
  profile{
    user{
      username
      email
      firstName
      lastName
    }
  }
}
`);

export var update_profile = api_wrapper(`
mutation (@autodeclare) {
  updateProfile(
    input: {
      username: $username
      email: $email
      firstName: $firstName
      lastName: $lastName
    }
  ) {
    user{
      username
    }
    errors{
      messages
    }
  }
}
`);
