const EARTH_RADIUS_M = 6371000;
const REF_Y = 0;

export function initViewer(czml) {
  var options3D = {
    fullscreenButton: true,
    sceneModePicker: true,
    baseLayerPicker: false,
    infoBox: false,
    geocoder: false,
    navigationHelpButton: false,
    selectionIndicator: true,
    scene3DOnly: false,
    imageryProvider: imageryProvider,
    clockViewModel: this.clock,
    useBrowserRecommendedResolution: true,
    resolutionScale: window.devicePixelRatio
  };

  var imageryProvider = new Cesium.UrlTemplateImageryProvider({
    url:
      "https://cog-tiles.s3.us-gov-west-1.amazonaws.com/bing/{z}/{x}/{y}.jpg",
    maximumLevel: 12,
    tilingScheme: new Cesium.WebMercatorTilingScheme({
      numberOfLevelZeroTilesX: 2,
      numberOfLevelZeroTilesY: 2
    })
  });

  var viewer = new this.cesium.Viewer("cesium-container", options3D);
  if (czml) {
    let czmlDataSource = new this.cesium.CzmlDataSource("studyCZML");
    czmlDataSource.load(JSON.parse(czml)).then(source => {
      viewer.dataSources.add(source);
    });
  } else {
    var planeEntities = new Cesium.CustomDataSource("planeEntities");

    viewer.dataSources.add(planeEntities);
  }

  viewer.selectedEntityChanged.addEventListener(newEntity => {
    this.selectedEntity = newEntity;
  });

  viewer.extend(this.cesium.viewerCesiumNavigationMixin, {});
  this.viewer = viewer;
}

/**
 * @param {[object]} planes - The array of plane groups data stored on this.planes and stored in the backend
 *  as Constellation.planes
 * @returns {[[Cesium.Cartesian3]]} - A 2-dimensional array that holds the positions for all of the orbital planes
 */
export function getAllPlanePositions(planes) {
  return planes.map(p => getPlaneGroupPositions(p));
}

/**
 * This function takes a "planeGroup" object (i.e. an element in this.planes) and returns a 2-dimensional
 * array where each row is an array of 3-dimensional coordinates.
 * @param {object} planeGroup - One of the elements in this.planes/Constellation.planes
 * @returns {[[Cesium.Cartesian3]]} positions - A 2-dimensional array where each row is an array of 3D coordinates
 *   representing an orbital plane in that group
 */
export function getPlaneGroupPositions(planeGroup) {
  const positions = [];
  for (var i = 0; i < planeGroup.numberOfPlanes; i++) {
    positions.push(
      getPlanePositions({
        altitude: planeGroup.altitude,
        inclination: planeGroup.inclination,
        RAAN: i * planeGroup.planeSpacing + planeGroup.firstRAAN
      })
    );
  }
  return positions;
}

/**
 * This function returns an array of 3-dimensional points that represent the positions that make
 * up a single orbital plane or an entity whose position lies somewhere along that oribtal plane.
 * Orbital planes are an emergent property of the data structure that we use to store orbital plane groups,
 * so we don't have a 1-to-1 correspondence between a "plane" and some data object. Instead, we store
 * properties about the group of orbital planes such as the total number of planes at that inclination,
 * the RAAN of the first plane, and the plane spacing between each plane in that "plane group". So the
 * `plane` object passed to this function is dynamically computed based on those values, and will have
 * a shape like:
 * {
 *   "altitude": Number,
 *   "inclination": Number,
 *   "RAAN": Number,
 * }
 * @param {object} plane - The orbital plane data
 * @param {number} pointSpacing - The amount of angular space between each point on the orbital plane
 * @returns {[Cesium.Cartesian3]} - An array of 3D points that represent either an orbital plane or an entity's
 *   position somewhere along that orbital plane.
 */
export function getPlanePositions(plane, pointSpacing) {
  pointSpacing = pointSpacing ?? 1;
  // Cesium expects these values in meters, but our UI instructs the user to specify kilometers so
  // we must convert the value to meters by multiplying the altitude by 1000.
  const semiMajorAxis = parseFloat(plane.altitude) * 1e3;
  const inclinationMatrix = Cesium.Matrix3.fromRotationX(
    Cesium.Math.toRadians(plane.inclination)
  );
  const raanMatrix = Cesium.Matrix3.fromRotationZ(
    Cesium.Math.toRadians(plane.RAAN)
  );

  const positions = [];
  for (var i = 0; i <= 360; i += pointSpacing) {
    const point = new Cesium.Cartesian3.fromDegrees(i, 0, semiMajorAxis);
    const rotateX = Cesium.Matrix3.multiplyByVector(
      inclinationMatrix,
      point,
      new Cesium.Cartesian3()
    );
    const rotateZ = Cesium.Matrix3.multiplyByVector(
      raanMatrix,
      rotateX,
      new Cesium.Cartesian3()
    );
    positions.push(rotateZ);
  }

  return positions;
}

export function getSatellitePositions(planes) {
  const positions = [];
  planes.forEach((planeGroup, pgi) => {
    for (let i = 0; i < planeGroup.numberOfPlanes; i++) {
      const plane = {
        altitude: planeGroup.altitude,
        inclination: planeGroup.inclination,
        RAAN: i * planeGroup.planeSpacing + planeGroup.firstRAAN
      };
      const spacing = 360 / planeGroup.numberOfSatellitesPerPlane;
      positions.push(getPlanePositions(plane, spacing));
    }
  });
  return positions;
}

/**
 * Initializes the Cesium Viewer and binds it to a DOM element
 * @param {Cesium} cesium - A reference to the global Cesium instance
 * @param {string} cesiumContainerId - The CSS selector ID of the div that will hold the viewer
 * @returns {Cesium.Viewer} - An instance of a Cesium Viewer bound to the div that matches `cesiumContainerId`
 */
export function initializeCesiumViewer(cesium, cesiumContainerId) {
  cesiumContainerId = cesiumContainerId || "cesium-container";

  const options = {
    baseLayerPicker: false,
    fullscreenButton: true,
    geocoder: false,
    imageryProvider: new Cesium.UrlTemplateImageryProvider({
      maximumLevel: 12,
      tilingScheme: new Cesium.WebMercatorTilingScheme({
        numberOfLevelZeroTilesX: 2,
        numberOfLevelZeroTilesY: 2
      }),
      url:
        "https://cog-tiles.s3.us-gov-west-1.amazonaws.com/bing/{z}/{x}/{y}.jpg"
    }),
    infoBox: false,
    navigationHelpButton: false,
    requestRenderMode: true,
    resolutionScale: window.devicePixelRatio,
    scene3DOnly: false,
    sceneModePicker: true,
    selectionIndicator: true,
    useBrowserRecommendedResolution: true
  };

  return new cesium.Viewer(cesiumContainerId, options);
}

/**
 * Loads CZML into a Cesium.Viewer as a CzmlDataSource
 * @param {string} czml - A CZML string to load into the Cesium viewer
 * @param {Cesium} cesium - A reference to the global Cesium instance
 * @param {Cesium.Viewer} viewer - An instance of Cesium.Viewer that the CZML will be loaded into
 * @param {string} [dataSourceName] - A string that will be the name of the CzmlDataSource (internal to Cesium)
 * @returns {Promise} - Resolves with a call to Cesium.Viewer.DataSourceCollection.add, which returns a Promise
 */
export function loadCzmlIntoViewer(czml, cesium, viewer, dataSourceName) {
  dataSourceName = dataSourceName || "studyCZML";
  const czmlDataSource = new cesium.CzmlDataSource(dataSourceName);

  return new Promise((resolve, reject) => {
    czmlDataSource
      .load(JSON.parse(czml))
      .then(source => {
        resolve(viewer.dataSources.add(source));
      })
      .catch(err => reject(err));
  });
}

export function getRAAN(planeNum, spacing, raan) {
  var newRAAN = raan;

  for (var i = 1; i < planeNum; i++) {
    newRAAN = newRAAN + spacing;
  }
  return newRAAN;
}

export function getPlanePositionsOld(orbit, planeNum, spacing) {
  spacing = spacing || 0;

  var firstRAAN = parseFloat(orbit.raan);
  var planeRAAN = getRAAN(planeNum, parseInt(spacing), firstRAAN);
  var semiMajorAxis = parseFloat(orbit.altitude) * 1000;
  var semiMinorAxis = semiMajorAxis;

  var inclinationMatrix = Cesium.Matrix3.fromRotationX(
    Cesium.Math.toRadians(orbit.inclination)
  );

  var raanMatrix = Cesium.Matrix3.fromRotationZ(
    Cesium.Math.toRadians(planeRAAN)
  );

  var positions = [];
  for (var i = 0; i <= 360; i += 10) {
    var axis =
      semiMinorAxis +
      Math.cos(i * (Math.PI / 180.0)) * (semiMajorAxis - semiMinorAxis);

    var point = new Cesium.Cartesian3.fromDegrees(i, REF_Y, axis);

    var rotateX = Cesium.Matrix3.multiplyByVector(
      inclinationMatrix,
      point,
      new Cesium.Cartesian3()
    );

    var rotateZ = Cesium.Matrix3.multiplyByVector(
      raanMatrix,
      rotateX,
      new Cesium.Cartesian3()
    );

    positions.push(rotateZ);
  }
  return positions;
}

export function getSatellitePositionsOld(orbit, planeNum, spacing, satNum) {
  var firstRAAN = parseFloat(orbit.raan);
  var planeRAAN = getRAAN(parseFloat(planeNum), parseFloat(spacing), firstRAAN);

  var semiMajorAxis = parseFloat(orbit.altitude) * 1000;
  var semiMinorAxis = semiMajorAxis;

  var inclinationMatrix = Cesium.Matrix3.fromRotationX(
    Cesium.Math.toRadians(orbit.inclination)
  );

  var raanMatrix = Cesium.Matrix3.fromRotationZ(
    Cesium.Math.toRadians(planeRAAN)
  );

  var satSpacing = 360 / satNum;

  var positions = [];
  var trueAnomalies = [];
  for (var i = 0; i < 360; i += satSpacing) {
    trueAnomalies.push(i);
    var axis =
      semiMinorAxis +
      Math.cos(i * (Math.PI / 180.0)) * (semiMajorAxis - semiMinorAxis);

    var point = new Cesium.Cartesian3.fromDegrees(i, REF_Y, axis);

    var rotateX = Cesium.Matrix3.multiplyByVector(
      inclinationMatrix,
      point,
      new Cesium.Cartesian3()
    );

    var rotateZ = Cesium.Matrix3.multiplyByVector(
      raanMatrix,
      rotateX,
      new Cesium.Cartesian3()
    );

    positions.push(rotateZ);
  }

  return { positions: positions, trueAnomalies: trueAnomalies };
}
