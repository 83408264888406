<template>
  <div class="snack-wrapper">
    <v-snackbar bottom :timeout="4500" v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      snackbar: true
    };
  }
};
</script>
