<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="scroll">
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formData.name"
                outlined
                dense
                color="accent"
                label="Name"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="text-search-small pa-1">
            <v-col cols="6">
              <h2 class="title">
                Set Sites
              </h2>
            </v-col>
            <v-col cols="6" class="d-flex flex-row justify-space-between">
              <h2 class="title flex-grow-1">
                Sites
              </h2>
              <v-text-field
                v-model="searchTerm"
                hide-details
                outlined
                dense
                color="accent"
                label="Search"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col class="sites-containers pa-1">
              <div class="scroll ">
                <v-row
                  v-if="loading"
                  justify="center"
                  align="center"
                  class="no-sites"
                >
                  <v-progress-circular
                    :size="50"
                    color="accent"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-row
                  v-else-if="!formData.sites.length"
                  align="center"
                  class="no-sites"
                >
                  <v-col class="pa-5 d-flex justify-center">
                    <span>Add a site from the right column.</span>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  v-for="(s, i) in formData.sites"
                  no-gutters
                  class="site-rows pa-2"
                  align="center"
                >
                  <v-col>
                    <span class="white-txt">{{ s.name }}</span> -
                    <span class="text--secondary">{{ s.siteTypePretty }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end">
                    <v-btn icon @click="removeSite(s)">
                      <v-icon color="accent">mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col class="sites-containers pa-1">
              <div class="scroll">
                <v-row
                  v-if="loading"
                  justify="center"
                  align="center"
                  class="no-sites"
                >
                  <v-progress-circular
                    :size="50"
                    color="accent"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-row
                  v-else-if="!filteredSites.length"
                  align="center"
                  class="no-sites"
                >
                  <v-col class="pa-5 d-flex flex-column align-center">
                    <!-- If there are no filteredSites then the right side of the UI is empty... -->
                    <div v-if="sites.length == 0">
                      <!-- If the actual, "raw" list of sites from the database is empty, show the "no sites, add one?" UI -->
                      <v-btn fab small color="accent" to="/sites/add">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <span class="mt-3"
                        >Add sites before creating a site set.</span
                      >
                    </div>

                    <!-- Otherwise, if the raw list of sites from the database is populated, but only filteredSites is empty, "no results found" -->
                    <span v-else class="mt-3">No results found.</span>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  v-for="(s, i) in filteredSites"
                  no-gutters
                  class="site-rows pa-2"
                  align="center"
                >
                  <v-col>
                    <span class="white-txt">{{ s.name }}</span> -
                    <span class="text--secondary">{{ s.siteTypePretty }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end">
                    <v-btn
                      icon
                      @click="isContained(s) ? removeSite(s) : addSite(s)"
                    >
                      <v-icon color="accent">
                        {{ isContained(s) ? "mdi-minus" : "mdi-plus" }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-flex justify-end">
      <v-btn @click="$router.back()">Cancel</v-btn>
      <v-btn color="accent" @click="save">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  getSites,
  getSiteGroupById,
  updateSiteGroup,
  addSiteGroup,
  getSiteTypes
} from "../api.js";
import { initMixin } from "../../mixins/index.js";
import { graphQlIdToInt } from "../../services/tools.js";
import { alert_dialog, snack } from "../../widgets/dialogs.js";
import { deepSearch } from "../services/deepSearch";

function isContained(site) {
  let isContained = this.formData.sites.findIndex(s => s.id === site.id);
  if (isContained === -1) {
    return false;
  } else return true;
}

function addSite(site) {
  if (!this.isContained(site)) {
    this.formData.sites.push(site);
    let index = this.formData.sites.findIndex(s => s.id === site.id);
  }
}

function removeSite(site) {
  let index = this.formData.sites.findIndex(s => s.id === site.id);
  if (index != -1) {
    this.formData.sites.splice(index, 1);
  }
}

function validate() {
  return true;
}

function save() {
  if (this.validate()) {
    let siteIds = this.formData.sites.map(s => {
      return graphQlIdToInt(s.id);
    });
    if (this.isEditMode) {
      updateSiteGroup({
        inputs: {
          siteGroupId: graphQlIdToInt(this.set.id),
          sites: siteIds,
          name: this.formData.name
        },
        callback: results => {
          snack("Successfully updated set.");
          this.$router.back();
        }
      });
    } else {
      addSiteGroup({
        inputs: {
          sites: siteIds,
          name: this.formData.name
        },
        callback: results => {
          snack("Successfully added set.");
          this.$router.back();
        }
      });
    }
  }
}

export default {
  props: ["isActive", "setId"],
  mixins: [initMixin],
  data() {
    return {
      valid: true,
      loading: false,
      set: null,
      searchTerm: null,
      sites: [],
      siteTypes: [],
      formData: {
        name: "",
        sites: []
      }
    };
  },

  mounted() {
    getSiteTypes().then(response => {
      this.siteTypes = JSON.parse(response.siteTypes);
    });
  },

  computed: {
    isEditMode: function() {
      return !!this.setId;
    },
    title: function() {
      return this.isEditMode ? `Edit ${this.set?.name} Set` : "Add Set";
    },
    filteredSites: function() {
      return this.searchTerm // If searchTerm exists...
        ? this.deepSearch(this.searchTerm, this.sites) // ..return a subset of this.sites containing only those objects that somehow match searchTerm
        : this.sites; // ...otherwise just return all of this.sites if there is no searchTerm.
    }
  },
  watch: {},
  methods: {
    init() {
      this.loading = true;
      getSites({
        inputs: {
          perpage: 5000
        },
        callback: res => {
          this.sites = res.sites.flat;
          this.loading = false;
        },
        error_callback: err => console.log(err)
      });

      if (this.isEditMode) {
        let setId = graphQlIdToInt(this.setId);

        getSiteGroupById({
          inputs: {
            id: setId,
            perpage: 5000
          },
          callback: res => {
            let set = res.siteGroups.flat[0];
            let sites = set.sites.flat.map(site => {
              return site;
            });

            set.sites = sites;
            this.set = set;
            this.formData.name = set.name;
            this.formData.sites = set.sites;
          },
          error_callback: err => console.log(err)
        });
      }
    },
    deepSearch: deepSearch,
    isContained: isContained,
    addSite: addSite,
    removeSite: removeSite,
    save: save,
    validate: validate
  }
};
</script>
<style lang="less">
.text-search-small {
  .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
  }

  .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
  }

  .v-text-field .v-input__control .v-input__slot label {
    margin-top: -5px !important;
  }

  .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
  }

  .v-text-field__details {
    margin: 2px !important;
  }
}
</style>
