const UNSEARCHABLE_TYPES = ["null", "undefined", "function"];

/**
 * Determines the type of a given value `v`. JavaScript's built-in `typeof` operator returns the value
 * 'object' for both Object and Array. This function is basically a version of typeof that can distinguish
 * between an Array and an Object.
 *
 * @param {*} v Any value
 * @returns {('array'|'object'|'boolean'|'function'|'number'|'string'|'undefined')} The type of the value `v`
 */
function _typeOf(v) {
  if (v === null) return "null";
  return Array.isArray(v) ? "array" : typeof v;
}

/**
 * Determines whether or not the string `searchTerm` is in the string `target`, case-insensitive.
 *
 * @param {string} searchTerm
 * @param {string} target
 * @returns {boolean}
 */
function _fuzzyMatch(searchTerm, target) {
  searchTerm = searchTerm.toLocaleLowerCase();
  target = target.toLocaleLowerCase();
  return target.includes(searchTerm);
}

/**
 * Determines whether or not a provided String `searchTerm` exists in one of the fields on the provided
 * Object `obj`. It's a deep search, meaning it will recursively match any field on `obj`, no matter how
 * deeply-nested that field may be.
 *
 * @param {string} searchTerm The string to search the object for
 * @param {object} obj The object whose fields will be searched for matches to `searchTerm`
 * @returns {boolean} Whether or not `searchTerm` was found in a value in one of the fields of `obj`
 */
function _deepSearchAnObject(searchTerm, obj) {
  if (obj === null || obj === undefined) return false;
  return Object.entries(obj).some(([key, entry]) => {
    const type = _typeOf(entry);
    if (UNSEARCHABLE_TYPES.includes(type)) return false;
    if (type === "object") {
      return _deepSearchAnObject(searchTerm, entry);
    } else {
      // If `entry` is a Number, convert it to a string
      entry = type === "number" ? entry.toString() : entry;
      return type === "array"
        ? _deepSearchAnArray(searchTerm, entry)
        : _fuzzyMatch(searchTerm, entry);
    }
  });
}

/**
 * This is a sister function to `_deepSearchAnObject`. They call each other and are functionally the same,
 * but this one works on arrays.
 *
 * @param {string} searchTerm The search term to look for matches for in the array
 * @param {array} arr The array to search
 */
function _deepSearchAnArray(searchTerm, arr) {
  arr.some(el => {
    const type = _typeOf(el);
    if (UNSEARCHABLE_TYPES.includes(type)) return false;
    if (type === "object") {
      return _deepSearchAnObject(searchTerm, el);
    } else {
      // If `el` is a Number, convert it to a string
      el = type === "number" ? el.toString() : el;
      return type === "array"
        ? _deepSearchAnArray(searchTerm, el)
        : _fuzzyMatch(searchTerm, el);
    }
  });
}

/**
 * Performs a case-insensitive deep search for a string `searchTerm` on an array of values (`data`).
 * An array representing a subset of matches (if any) are returned.
 *
 * @param {string} searchTerm The term to search for in the array of objects
 * @param {array<object>} data An array of objects to search for matches to `searchTerm` in
 * @returns {array<object>} A subset of the input `data` that contains the matches to `searchTerm` (if any exist)
 */
function deepSearch(searchTerm, data) {
  return data.filter(d => _deepSearchAnObject(searchTerm, d));
}

module.exports = {
  _fuzzyMatch: _fuzzyMatch,
  deepSearch: deepSearch,
  typeOf: _typeOf
};
