<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <v-card>
      <v-card-title primary-title>
        <div v-if="isIcon">
          <v-icon class="mr-2" v-if="icon" :color="iconColor">{{
            icon
          }}</v-icon>
        </div>
        {{ alertTitle }}
      </v-card-title>
      <v-card-text>
        <br />
        <h2>{{ message }}</h2>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return { dialog: true };
  },
  mounted() {},
  computed: {
    isIcon() {
      let mdi = this.title
        .slice()
        .split(",")[0]
        .split("-")[0];
      if (mdi === "mdi") {
        return true;
      } else {
        return false;
      }
    },
    iconColor() {
      if (this.isIcon) {
        return this.title.slice().split(",")[1];
      } else return null;
    },
    icon() {
      if (this.isIcon) {
        return this.title.slice().split(",")[0];
      } else return null;
    },
    alertTitle() {
      if (this.isIcon) {
        return this.title.slice().split(",")[2];
      } else return this.title;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.callback();
      this.$store.commit("set_dialog", null);
    }
  }
};
</script>
