<template>
  <div id="cesiumWrapper">
    <pass-info-box
      :selected="selectedEntity"
      :deselect="deselect"
      :passes="passes"
      :time="clock"
    ></pass-info-box>
    <div id="cesium-container" v-if="webgl"></div>
    <div v-else class="nogl">
      <h2>WebGL enabled is required to load this page.</h2>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";

import PassInfoBox from "../components/PassInfoBox.vue";
import { get_passes, get_satellite_czml } from "../api/api.js";
import get_cesium from "../../services/cesium.js";
import { dt as dtformat } from "../../services/filters.js";
import { graphQlIdToInt } from "../../services/tools.js";
import { alert_dialog } from "../../widgets/dialogs.js";

function getCzml() {
  return new Promise((resolve, reject) => {
    get_passes({
      inputs: {
        startTime: this.minISO,
        endTime: this.maxISO,
        perpage: 100
      },
      callback: res => {
        this.passes = res.passes.flat.filter(
          pass => pass.status === "SCHEDULED"
        );
        if (this.passes.length === 0) {
          alert_dialog("Error", "There are no upcoming passes to display.");
        }
        let satellites = this.passes.map(pass => {
          return graphQlIdToInt(pass.satellite.id);
        });
        get_satellite_czml({
          inputs: {
            startTime: this.minISO,
            endTime: this.maxISO,
            ids: satellites
          },
          callback: res => {
            let czml = JSON.parse(res.satelliteCzml);
            let czmlIcons = czml.map(cz => {
              if (cz.id !== "document") {
                let satName = cz.id.split("/")[1].split(" ")[1];
                cz.name = satName.toLowerCase();
                cz.label.text = satName.toUpperCase();
                cz.position.referenceFrame = "FIXED";
              }
              if (cz.billboard) {
                cz.billboard.image =
                  "/static/Source/demo-icons/SatelliteIcon.png";
                cz.billboard.scale = 0.51;
              }
              return cz;
            });
            resolve(czmlIcons);
          },
          error_callback: error => {
            resolve("error");
          }
        });
      },
      error_callback: error => {}
    });
  });
}

function getViewerExtentParams(that) {
  let smRectangle = that.cesium.Rectangle.fromDegrees(-120, -65, 80, 83);
  let rectangle = that.cesium.Rectangle.fromDegrees(-180, -90, 180, 90);
  let view = 1;
  let w = that.winsize.width;
  let h = that.winsize.height;

  if (w <= 1024 && h <= 1366) {
    rectangle = smRectangle;
  }
  if (w >= 1024 && h >= 900) {
    rectangle = smRectangle;
    view = 0.9;
  }
  if (w <= 1366 && h <= 800) {
    view = 1.1;
  }
  if (w <= 1600 && h <= 900) {
    view = 0.6;
  }
  if (w >= 1920 && h >= 1080) {
    view = 0.9;
  }

  return { rectangle: rectangle, view: view };
}

function loadWorldView() {
  this.cesium = get_cesium(this.$settings.ionkey);
  this.clock = new this.cesium.ClockViewModel();

  let { rectangle, view } = getViewerExtentParams(this);

  this.cesium.Camera.DEFAULT_VIEW_FACTOR = view;
  this.cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

  var imageryProvider = new Cesium.UrlTemplateImageryProvider({
    url:
      "https://cog-tiles.s3.us-gov-west-1.amazonaws.com/bing/{z}/{x}/{y}.jpg",
    maximumLevel: 12,
    tilingScheme: new Cesium.WebMercatorTilingScheme({
      numberOfLevelZeroTilesX: 2,
      numberOfLevelZeroTilesY: 2
    })
  });

  var options3D = {
    homeButton: false,
    fullscreenButton: true,
    sceneModePicker: true,
    baseLayerPicker: false,
    infoBox: false,
    geocoder: false,
    navigationHelpButton: false,
    selectionIndicator: true,
    scene3DOnly: false,
    sceneMode: this.cesium.SceneMode.SCENE2D,
    imageryProvider: imageryProvider,
    clockViewModel: this.clock,
    useBrowserRecommendedResolution: true,
    resolutionScale: window.devicePixelRatio
  };

  var viewer = new this.cesium.Viewer("cesium-container", options3D);

  viewer.timeline.makeLabel = dt => {
    return this.dtLabel(dt);
  };

  viewer.animation.viewModel.dateFormatter = dt => {
    return this.dateFormatter(dt);
  };
  viewer.animation.viewModel.timeFormatter = dt => {
    return this.timeFormatter(dt);
  };

  this.$nextTick(() => {
    this.resize(this.winsize);
  });

  this.getCzml().then(czml => {
    if (czml != "error") {
      viewer.dataSources.add(Cesium.CzmlDataSource.load(czml));
    }

    this.passes.map(pass => {
      let latLong = pass.groundStation.location.split(",");
      let lat = latLong[0];
      let long = latLong[1];
      viewer.entities.add({
        name: pass.groundStation.name,
        position: this.cesium.Cartesian3.fromDegrees(long, lat),
        billboard: {
          image: "/static/Source/demo-icons/GroundStationIcon.png",
          scale: 0.51
        }
      });
    });
  });

  viewer.selectedEntityChanged.addEventListener(newEntity => {
    this.selectedEntity = newEntity;
  });

  this.viewer = viewer;
}

function deselect() {
  this.viewer.selectedEntity = undefined;
}

function getAssocFeatures(passes) {
  let stations = [];
  let satellites = [];
  passes.forEach(pass => {
    if (stations.indexOf(pass.groundStation.name) === -1) {
      stations.push(pass.groundStation.name);
    }
    if (satellites.indexOf(pass.satellite.name) === -1) {
      satellites.push(pass.satellite.name.toLowerCase());
    }
  });
  return { stations: stations, satellites: satellites };
}

export default {
  name: "PassesWorldView",
  props: [
    "headerHeight",
    "satelliteIds",
    "filterChange",
    "filteredPasses",
    "minISO",
    "maxISO",
    "weekRange"
  ],
  components: { PassInfoBox },
  data() {
    return {
      selectedEntity: null,
      satCzml: null,
      scheduledSatellites: [],
      passes: [],
      clock: null,
      isDataLoading: true
    };
  },
  computed: {
    webgl() {
      return this.$store.state.webgl;
    },
    timezone() {
      return this.$store.state.timezone;
    },
    winsize() {
      if (this.$store) {
        return {
          width: this.$store.state.window_width,
          height: this.$store.state.window_height
        };
      }
      return { width: 1280, height: 720 };
    }
  },
  watch: {
    filterChange() {
      if (this.viewer.entities) {
        let { satellites, stations } = getAssocFeatures(this.filteredPasses);
        let stationEnts = this.viewer.entities.values;
        stationEnts.forEach(staEnt => {
          if (stations.indexOf(staEnt.name) === -1) {
            staEnt.show = false;
          } else {
            staEnt.show = true;
          }
        });

        if (this.viewer.dataSources.get(0)) {
          let satelliteEnts = this.viewer.dataSources.get(0).entities.values;
          satelliteEnts.forEach(satEnt => {
            if (satellites.indexOf(satEnt.name) === -1) {
              satEnt.show = false;
            } else {
              satEnt.show = true;
            }
          });
        }
      }
    },

    winsize(to, from) {
      this.resize(to);
    },
    weekRange() {
      if (this.viewer) {
        if (this.viewer.entities) {
          this.viewer.entities.removeAll();
        }
        this.viewer.destroy();
      }
      this.init();
    },
    timezone() {
      this.viewer.clockViewModel.shouldAnimate = false;
      this.viewer.timeline.destroy();
      var el = document.querySelector(".cesium-viewer-timelineContainer");
      var clock = this.viewer.clock;

      var timeline = new this.cesium.CogTimeline(el, clock);
      timeline.addEventListener(
        "settime",
        e => {
          var clock = e.clock;
          clock.currentTime = e.timeJulian;
          clock.shouldAnimate = false;
        },
        false
      );
      timeline.zoomTo(clock.startTime, clock.stopTime);
      this.viewer._timeline = timeline;

      this.viewer.clockViewModel.shouldAnimate = true;
      setTimeout(() => {
        this.viewer.clockViewModel.shouldAnimate = false;
      }, 1);
    }
  },
  methods: {
    init() {
      if (this.webgl) {
        this.loadWorldView();
      }
    },
    resize(to) {
      var headerHeight = this.headerHeight();
      var h = to.height - headerHeight - 97;
      document.getElementById("cesium-container").style.height = `${h}px`;
    },
    dtLabel(dt) {
      dt = this.cesium.JulianDate.toDate(dt);
      dt = DateTime.fromJSDate(dt);
      return dtformat(dt, "SHORT");
    },
    dateFormatter(dt) {
      dt = this.cesium.JulianDate.toDate(dt);
      dt = DateTime.fromJSDate(dt);
      return dtformat(dt, "LL/dd/yy");
    },
    timeFormatter(dt) {
      dt = this.cesium.JulianDate.toDate(dt);
      dt = DateTime.fromJSDate(dt);
      return dtformat(dt, "HH:mm:ss");
    },
    loadWorldView: loadWorldView,
    getCzml: getCzml,
    deselect: deselect
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="less">
#cesiumWrapper {
  .nogl {
    padding: 60px;
    text-align: center;
  }
}
</style>
