import axios from "axios";

import store from "../store/index.js";

export default {
  is_authed() {
    return new Promise((resolve, reject) => {
      axios
        .get("/accounts/check-auth")
        .then(response => {
          store.commit("login", response.data);
          resolve(response.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};
