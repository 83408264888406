<template>
  <v-dialog v-model="isActive" persistent scrollable class="fixed-dialog">
    <v-form ref="form" lazy-validation @submit.prevent="do_save">
      <v-card class="cog-map-edit">
        <v-card-title class="d-flex justify-space-between ml-2 mr-2">
          <h2 v-if="target">Edit Target</h2>
          <h2 v-else>Add Target</h2>
          <v-btn-toggle v-model="type" group>
            <v-btn value="STATIONARY">
              <v-icon left>mdi-pin</v-icon>
              Stationary
            </v-btn>
            <v-btn v-if="missionType === 'CUSTODYTRK'" value="MOBILE">
              <v-icon left>mdi-target</v-icon>
              Mobile
            </v-btn>
          </v-btn-toggle>
        </v-card-title>
        <v-card-text class="scroll">
          <v-container>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  color="accent"
                  label="Name"
                  :rules="rules.name"
                  v-model="form.name"
                  dense
                  outlined
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-select
                  color="accent"
                  item-color="accent"
                  label="Priority"
                  name="priority"
                  v-model="form.priority"
                  :items="priorities"
                  dense
                  outlined
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters justify="space-between">
              <v-col cols="6">
                <v-datetime-picker
                  v-model="form.start"
                  :label="labels.start"
                  dateFormat="MM/dd/yyyy"
                  :timePickerProps="{ format: '24hr' }"
                  :textFieldProps="{
                    appendIcon: 'mdi-calendar',
                    color: 'accent',
                    dense: true,
                    outlined: true
                  }"
                  :dialogProps="{ persistent: true }"
                >
                  <template v-slot:dateIcon>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-col cols="6">
                <v-datetime-picker
                  v-model="form.end"
                  :label="labels.end"
                  dateFormat="MM/dd/yyyy"
                  :timePickerProps="{ format: '24hr' }"
                  :textFieldProps="{
                    appendIcon: 'mdi-calendar',
                    color: 'accent',
                    dense: true,
                    outlined: true
                  }"
                  :dialogProps="{ persistent: true }"
                >
                  <template v-slot:dateIcon>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="mb-3">
                <v-btn color="secondary" @click="pullTime()" small
                  >Copy Times from Mission</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-if="isMobile" no-gutters>
              <v-col>
                <v-row no-gutters>
                  <h3 class="title white-txt">Tracking</h3>
                </v-row>

                <v-row no-gutters justify="space-between">
                  <v-col>
                    <v-datetime-picker
                      v-model="form.mobile.lastCollect"
                      :label="labels.last"
                      dateFormat="MM/dd/yyyy"
                      :timePickerProps="{ format: '24hr' }"
                      :textFieldProps="{
                        appendIcon: 'mdi-calendar',
                        color: 'accent',
                        dense: true,
                        outlined: true
                      }"
                      :dialogProps="{ persistent: true }"
                    >
                      <template v-slot:dateIcon>
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template v-slot:timeIcon>
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>

                  <v-col>
                    <v-text-field
                      color="accent"
                      label="Altitude (m)"
                      name="name"
                      type="number"
                      v-model="form.mobile.altitude"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col>
                    <v-text-field
                      color="accent"
                      label="Initial Uncertainty Radius (m)"
                      name="name"
                      type="number"
                      v-model="form.mobile.radius"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" justify="start">
                  <v-col cols="4">
                    <v-text-field
                      color="accent"
                      label="Velocity (m/s)"
                      name="name"
                      type="number"
                      v-model="form.mobile.speed"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="1" class="d-flex justify-center">
                    <v-icon class="mb-5">mdi-plus-minus-variant</v-icon>
                  </v-col>

                  <v-col cols="3" class="d-flex">
                    <v-text-field
                      color="accent"
                      label="Uncertainty"
                      name="name"
                      type="number"
                      v-model="form.mobile.speedU"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" justify="start">
                  <v-col cols="4">
                    <v-text-field
                      color="accent"
                      label="Heading (deg)"
                      name="name"
                      type="number"
                      v-model="form.mobile.heading"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="1" class="d-flex justify-center">
                    <v-icon class="mb-5">mdi-plus-minus-variant</v-icon>
                  </v-col>

                  <v-col cols="3" class="d-flex">
                    <v-text-field
                      color="accent"
                      label="Uncertainty"
                      name="name"
                      type="number"
                      v-model="form.mobile.headingU"
                      dense
                      outlined
                      :rules="rules.req"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-row no-gutters justify="space-between" align="center">
                  <h3 class="title white-txt">{{ locationTitle }}</h3>

                  <div :style="map_show" v-if="webgl">
                    <v-btn x-small color="accent" @click="show_man()">
                      Enter Coordinates
                    </v-btn>
                  </div>
                  <div :style="man_show" v-if="webgl">
                    <v-btn x-small color="accent" @click="show_map()">
                      Cancel Coordinates
                    </v-btn>
                  </div>
                </v-row>

                <v-sheet
                  outlined
                  :style="man_show"
                  class="pa-2 man-coord scroll"
                >
                  <div class="scroll">
                    <em v-if="!webgl">WebGL required to use map widget.</em>
                    <br v-if="!webgl" />

                    <div class="ml-2 mb-2 grey-txt">
                      <em>Enter one coordinate for a point.</em>
                      <br />
                      <em>Enter at least 3 coordinates for a polygon.</em>
                    </div>

                    <v-container>
                      <v-row v-for="(c, i) in man_coords" no-gutters>
                        <v-col>
                          <v-text-field
                            color="accent"
                            type="number"
                            :label="`Latitude ${i + 1}`"
                            v-model="c[1]"
                            dense
                            outlined
                            autocomplete="off"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            color="accent"
                            type="number"
                            :label="`Longitude ${i + 1}`"
                            v-model="c[0]"
                            dense
                            outlined
                            autocomplete="off"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <span v-if="i != 0">
                            <v-btn icon @click="rm_coord(i)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </span>
                          <span v-else class="fake-icon">&nbsp;</span>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="pl-2">
                      <v-btn small @click="add_coord()"> Add Coordinate </v-btn>
                      <v-btn
                        small
                        color="accent"
                        @click="set_coords()"
                        v-if="webgl"
                      >
                        Set Coordinates
                      </v-btn>
                    </div>
                  </div>
                </v-sheet>
                <v-sheet outlined :style="map_show" v-if="webgl">
                  <mapbox
                    v-if="loaded"
                    access-token="pk.eyJ1Ijoic3BhY2VwYW50aGVyIiwiYSI6ImNrbzh4aTJrcjFrZmsyd3J3ZXJjbmRob3EifQ.bB0HaTIzGa-nQOoErJrvZA"
                    :map-options="{
                      style: 'mapbox://styles/mapbox/satellite-streets-v11',
                      center: center,
                      zoom: zoom
                    }"
                    @map-init="map_init"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3 mr-3">
          <v-btn @click="dialogClose()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="accent"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import Mapbox from "mapbox-gl-vue";
import deepcopy from "deepcopy";

import { initMixin } from "../../../mixins/index.js";
import { alert_dialog } from "../../../widgets/dialogs.js";
import { polygon_centroid } from "../../../services/tools.js";
import { current_short_zone, naive_dt } from "../../../services/tools.js";

function dialogClose() {
  this.$emit("update:isActive", null);
}

function getInitializedData() {
  var form = {
    name: "",
    start: null,
    end: null,
    priority: 2,
    mobile: {
      lastCollect: null,
      altitude: null,
      radius: null,
      speed: null,
      speedU: null,
      heading: null,
      headingU: null
    }
  };

  var center = [-145.25, 57.27];
  var type = "STATIONARY";

  var priorities = [
    { text: "High", value: 1 },
    { text: "Normal", value: 2 },
    { text: "Low", value: 3 }
  ];

  if (this.target) {
    form.name = this.target.name;
    form.targetType = this.target.targetType;
    form.priority = this.target.priority;
    form.start = naive_dt(this.target.start);
    form.end = naive_dt(this.target.end);

    if (this.target.isMobile) {
      form.mobile.radius = this.target.radius;
      form.mobile.speed = this.target.speed;
      form.mobile.speedU = this.target.speedU;
      form.mobile.heading = this.target.heading;
      form.mobile.headingU = this.target.headingU;
      type = "MOBILE";
    }

    var coords = this.target.coordinates;
    if (this.target.targetType == "Polygon") {
      center = polygon_centroid(coords);
    } else {
      center = coords;
    }
  }

  var man_show = { display: "none" };
  var man_coords = [["", ""]];

  if (!this.$store.state.webgl) {
    man_show = { display: "block" };

    if (this.target) {
      if (this.target.targetType == "Polygon") {
        man_coords = coords;
        man_coords.pop();
      } else {
        man_coords = [coords];
      }
    }
  }

  return {
    zone: current_short_zone(),
    dialog: true,
    loaded: false,
    type: type,
    man_coords: man_coords,
    man_show: man_show,
    map_show: { display: "block" },
    map: null,
    draw: null,
    zoom: 3.5,
    center: center,
    rules: {
      name: [v => !!v || "Name is required"]
    },
    form: form,
    priorities: priorities
  };
}

export default {
  props: ["isActive", "index", "missionType", "target", "time"],
  components: { Mapbox },
  mixins: [initMixin],

  data: getInitializedData,
  watch: {
    isActive: function(n, o) {
      n ? this.dialogOpen() : this.dialogClose();
    }
  },
  computed: {
    labels() {
      return {
        start: `Start Time (${this.zone})`,
        end: `End Time (${this.zone})`,
        last: `Last Known Location Time (${this.zone})`
      };
    },
    webgl() {
      return this.$store.state.webgl;
    },
    isMobile() {
      if (this.type === "MOBILE") {
        return true;
      } else return false;
    },
    locationTitle() {
      if (this.type === "MOBILE") {
        return "Last Known Location";
      } else return "Location";
    }
  },
  methods: {
    pullTime() {
      var t = this.time;
      this.form.start = naive_dt(t[0]);
      this.form.end = naive_dt(t[1]);
    },
    init() {
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
    dialogClose: dialogClose,
    getInitializedData: getInitializedData,
    map_init(map) {
      this.map = map;
      var draw_opts = {
        displayControlsDefault: false,
        controls: {
          polygon: true,
          point: true,
          trash: true
        },
        defaultMode: "draw_polygon"
      };

      this.draw = new MapboxDraw(draw_opts);
      this.map.addControl(this.draw);
      this.map.on("draw.create", e => {
        this.draw_created(e);
      });

      if (this.target) {
        if (this.target.targetType == "Polygon") {
          this._set_coords(this.target.coordinates, false);
        } else {
          this._set_coords([this.target.coordinates], false);
        }
      }

      this.map.on("idle", () => {
        this.map.resize();
      });
    },
    draw_created(event) {
      this.draw.getAll().features.forEach(f => {
        if (f.id != event.features[0].id) {
          this.draw.delete([f.id]);
        }
      });
    },
    show_map() {
      this.man_show = { display: "none" };
      this.map_show = { display: "block" };
    },
    show_man() {
      this.man_show = { display: "block" };
      this.map_show = { display: "none" };
      this.man_coords = [];
      var feature = this.draw.getAll().features[0];

      if (feature.geometry.type == "Point") {
        this.man_coords.push([...feature.geometry.coordinates]);
      } else {
        feature.geometry.coordinates[0].forEach((c, i) => {
          if (i < feature.geometry.coordinates[0].length - 1) {
            this.man_coords.push([...c]);
          }
        });
      }

      if (this.man_coords.length == 0) {
        this.man_coords.push(["", ""]);
      } else if (this.man_coords[0][0] === undefined) {
        this.man_coords[0] = ["", ""];
      }
    },
    do_save() {
      if (this.$refs.form.validate()) {
        if (this.webgl) {
          var feature = this.draw.getAll().features[0];
          if (
            feature.geometry.type == "Polygon" &&
            feature.geometry.coordinates[0].length < 4
          ) {
            alert_dialog("Error", "A Point or Polygon is required.");
            return;
          }
        } else {
          if (this.man_coords.length == 0) {
            alert_dialog("Error", "A Point or Polygon is required.");
            return;
          } else if (this.man_coords.length == 2) {
            alert_dialog(
              "Error",
              "3 points or more are required for a polygon."
            );
            return;
          }
        }

        if (
          (this.form.start && !this.form.end) ||
          (!this.form.start && this.form.end)
        ) {
          alert_dialog("Error", "Start and End must be filled out together.");
          return;
        }

        if (
          this.form.start &&
          this.form.end &&
          this.form.start.getTime() >= this.form.end.getTime()
        ) {
          alert_dialog("Error", "Start time must be before end time.");
          return;
        }

        var target = Object.assign({}, this.form);
        target.id = uuidv4();
        target.isMobile = this.isMobile;

        if (this.isMobile) {
          Object.assign(target, target.mobile);
        }
        delete target.mobile;

        if (this.webgl) {
          if (feature.geometry.type == "Point") {
            target.coordinates = feature.geometry.coordinates;
            target.targetType = "Point";
          } else {
            target.coordinates = feature.geometry.coordinates[0];
            target.targetType = "Polygon";
          }
        } else {
          this.man_coords.forEach(c => {
            c[0] = parseFloat(c[0]);
            c[1] = parseFloat(c[1]);
          });

          if (this.man_coords.length == 1) {
            target.coordinates = this.man_coords[0];
            target.targetType = "Point";
          } else {
            target.coordinates = this.man_coords;
            target.coordinates.push(this.man_coords[0]);
            target.targetType = "Polygon";
          }
        }

        target.altitude = parseFloat(target.altitude);
        target.speed = parseFloat(target.speed);
        target.speedU = parseFloat(target.speedU);
        target.heading = parseFloat(target.heading);
        target.heading = parseFloat(target.headingU);
        target.radius = parseFloat(target.radius);

        this.$emit("commit", this.index, target);
        this.dialogClose();
      } else {
        alert_dialog("Error", "Fix errors in form.");
      }
    },
    add_coord() {
      this.man_coords.push(["", ""]);
    },
    rm_coord(i) {
      this.man_coords.splice(i, 1);
    },
    _set_coords(coords, close_loop) {
      coords = deepcopy(coords);
      coords.forEach(c => {
        c.forEach((value, i) => {
          c[i] = parseFloat(value);
        });
      });

      if (coords.length == 1) {
        this.draw.deleteAll();
        this.draw.add({ type: "Point", coordinates: coords[0] });
      } else if (coords.length > 1) {
        this.draw.deleteAll();
        if (close_loop) {
          coords.push([...coords[0]]);
        }

        this.draw.add({ type: "Polygon", coordinates: [coords] });
      }
    },
    set_coords() {
      this.show_map();
      this._set_coords(this.man_coords, true);
    }
  }
};
</script>
<style lang="less">
.v-dialog {
  max-width: 800px;
  max-height: 875px !important;
}

.man-coord {
  height: 430px;
}

.cog-map-edit {
  .fake-icon {
    width: 36px;
    display: inline-block;
  }

  #map {
    width: 100%;
    height: 430px;
  }

  .mapboxgl-ctrl-attrib-inner a {
    color: black;
  }

  .man-butt {
    padding-bottom: 5px;
  }
}
</style>
