<template>
  <div class="set-timezone">
    <v-select
      color="accent"
      item-color="accent"
      :items="zones"
      dense
      :value="timezone"
      @change="zone_change"
    ></v-select>
  </div>
</template>
<script>
import deepcopy from "deepcopy";

import { TIMEZONES } from "../services/constants.js";

export default {
  data() {
    return {
      tz: this.$store.state.timezone,
      zones: deepcopy(TIMEZONES)
    };
  },
  computed: {
    timezone() {
      return this.$store.state.timezone;
    }
  },
  methods: {
    zone_change(newzone) {
      this.$store.commit("set_timezone", newzone);
    }
  }
};
</script>
<style lang="less">
.set-timezone {
  display: inline-block;
  width: 200px;
  padding-right: 8px;

  .v-text-field__details {
    display: none;
  }
}
</style>
