import { DateTime } from "luxon";

import { dt as dtformat } from "./filters.js";

class CogCloudImageProvider extends Cesium.UrlTemplateImageryProvider {
  constructor(options) {
    options.url = "/mission/tiles/cloud/{sim}/{Time}/{z}/{x}/{y}";
    options.credit = new Cesium.Credit("OpenWeather Maps 2.0");
    options.maximumLevel = 2;
    options.customTags = {
      /* eslint-disable-next-line no-unused-vars */
      Time: function(imageryProvider, x, y, level) {
        return imageryProvider.time;
      },
      /* eslint-disable-next-line no-unused-vars */
      sim: function(imageryProvider, x, y, level) {
        return imageryProvider.sim;
      }
    };

    super(options);

    this.time = options.time;
    this.sim = atob(options.sim).split(":")[1];
  }
}

class CogTimeline extends Cesium.Timeline {
  constructor(el, clock) {
    super(el, clock);
  }

  makeLabel(dt) {
    dt = Cesium.JulianDate.toDate(dt);
    dt = DateTime.fromJSDate(dt);
    return dtformat(dt, "SHORT");
  }
}

export default function get_cesium(ionkey) {
  if (!Cesium.is_patched) {
    Cesium.Ion.defaultAccessToken = ionkey;
    Cesium.is_patched = true;
    Cesium.CylinderGeometryLibrary.computePositions = computePositions;
    Cesium.CogCloudImageProvider = CogCloudImageProvider;
    Cesium.CogTimeline = CogTimeline;
  }

  return Cesium;
}

function computePositions(length, topRadius, bottomRadius, slices, fill) {
  var topZ = length;
  var bottomZ = 0.0;

  var twoSlice = slices + slices;
  var size = fill ? 2 * twoSlice : twoSlice;
  var positions = new Float64Array(size * 3);
  var i;
  var index = 0;
  var tbIndex = 0;
  var bottomOffset = fill ? twoSlice * 3 : 0;
  var topOffset = fill ? (twoSlice + slices) * 3 : slices * 3;

  for (i = 0; i < slices; i++) {
    var angle = (i / slices) * Cesium.Math.TWO_PI;
    var x = Math.cos(angle);
    var y = Math.sin(angle);
    var bottomX = x * bottomRadius;
    var bottomY = y * bottomRadius;
    var topX = x * topRadius;
    var topY = y * topRadius;

    positions[tbIndex + bottomOffset] = bottomX;
    positions[tbIndex + bottomOffset + 1] = bottomY;
    positions[tbIndex + bottomOffset + 2] = bottomZ;

    positions[tbIndex + topOffset] = topX;
    positions[tbIndex + topOffset + 1] = topY;
    positions[tbIndex + topOffset + 2] = topZ;
    tbIndex += 3;
    if (fill) {
      positions[index++] = bottomX;
      positions[index++] = bottomY;
      positions[index++] = bottomZ;
      positions[index++] = topX;
      positions[index++] = topY;
      positions[index++] = topZ;
    }
  }

  return positions;
}
