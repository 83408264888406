import { api_wrapper, api_wrapper2 } from "../services/api_wrapper.js";

export const getSiteTypes = api_wrapper2(`
query {
  siteTypes
}
`);

export const getSites = api_wrapper(`
query (@autodeclare){
    sites(first: $perpage) {
      edges {
        node {
          name
          id
          locationPoint
          locationPolygon
          geometricType
          siteType
          siteTypePretty
          country
          locationPoint
          locationPolygon
        }
      }
      geojson
    }
  }
`);

export const getSiteById = api_wrapper(`
query ($id: Float, $first:Int){
    sites(first: $first, id:$id) {
      edges {
        node {
          name
          id
          locationPoint
          locationPolygon
          geometricType
          siteType
          siteTypePretty
          country
          locationPoint
          locationPolygon
        }
      }
      geojson
    }
  }
`);

export const addSites = api_wrapper(`
mutation ($site:JSONString, $sites:JSONString) {
  addSite(site:$site, sites:$sites) {
    result
  }
}
`);

export const updateSite = api_wrapper(`
mutation ($site:JSONString!) {
  updateSite(site: $site) {
    result
  }
}
`);

export const deleteSitesById = api_wrapper(`
mutation ($siteIds:[ID]!) {
  deleteSites(siteIds: $siteIds) {
    result
  }
}
`);

export const getSiteGroups = api_wrapper(`
query (@autodeclare) {
  siteGroups (first:$perpage){
    edges {
      node {
        id
        name
        sites (first:$perpage){
          edges{
            node{
              name
              geometricType
              siteType
              siteTypePretty
              country
              id
              locationPoint
              locationPolygon
            }
          }
        }
      }
    }
  }
}`);

export const getSiteGroupById = api_wrapper(`
query ($id: Float, $perpage:Int) {
  siteGroups (id:$id, first:1){
    edges {
      node {
        id
        name
        sites (first:$perpage){
          edges{
            node{
              name
              geometricType
              siteType
              siteTypePretty
              country
              id
            }
          }
        }
      }
    }
  }
}`);

export const addSiteGroup = api_wrapper(`
mutation ($name:String!, $sites: [ID]!) {
  addSiteGroup(name: $name, sites:$sites) {
    result
  }
}
`);

export const updateSiteGroup = api_wrapper(`
mutation ($siteGroupId:ID!,$name:String!, $sites: [ID]!) {
  updateSiteGroup(siteGroupId: $siteGroupId, name:$name, sites:$sites) {
    result
  }
}
`);

export const deleteSiteGroup = api_wrapper(`
mutation ($siteGroupId:ID!) {
  deleteSiteGroup(siteGroupId: $siteGroupId) {
    result
  }
}
`);
