import { DateTime } from "luxon";

import { dt } from "./filters.js";
import store from "../store/index.js";

export function formatDateString(date) {
  date = new Date(date);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export function round(val, places) {
  if (val !== undefined && val != null) {
    var m = 1;
    for (var i = 0; i < places; i++) {
      m = m * 10;
    }

    val = val * m;
    val = Math.round(val);
    val = val / m;
  }

  return val;
}

export function current_short_zone() {
  return dt(new Date(), "ZZZZ");
}

export function apply_zone(value) {
  if (value) {
    value = DateTime.fromObject({
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      day: value.getDate(),
      hour: value.getHours(),
      minute: value.getMinutes(),
      second: value.getSeconds()
    });

    value = value.toISO({ includeOffset: false }) + " " + store.state.timezone;
    value = value.replace("T", " ");
    value = DateTime.fromFormat(value, "yyyy-LL-dd HH:mm:ss.u z");
    return value.toISO();
  }

  return value;
}

export function naive_dt(value) {
  if (value) {
    if (value instanceof Date) {
      value = DateTime.fromJSDate(value);
    } else {
      value = DateTime.fromISO(value);
    }

    value = value.setZone(store.state.timezone);
    value = new Date(
      value.year,
      value.month - 1,
      value.day,
      value.hour,
      value.minute,
      value.second
    );
  }

  return value;
}

export function replace_zone(value) {
  if (value) {
    value = apply_zone(value);
    value = DateTime.fromISO(value);
    return value.toJSDate();
  }

  return value;
}

export function flatten(results) {
  if (results && typeof results == "object") {
    var keys = Object.keys(results);
    for (var r of keys) {
      if (r == "edges") {
        var flat = [];
        results[r].forEach(item => {
          if (item && item.node) {
            flatten(item.node);
            flat.push(item.node);
          }
        });

        results.flat = flat;
        delete results.edges;
      } else if (r == "flat") {
        return;
      } else {
        flatten(results[r]);
      }
    }
  }
}

export function polygon_centroid(pts) {
  var first = pts[0],
    last = pts[pts.length - 1];
  if (first[0] != last[0] || first[1] != last[1]) pts.push(first);
  var twicearea = 0,
    x = 0,
    y = 0,
    nPts = pts.length,
    p1,
    p2,
    f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i];
    p2 = pts[j];
    f = p1[0] * p2[1] - p2[0] * p1[1];
    twicearea += f;
    x += (p1[0] + p2[0]) * f;
    y += (p1[1] + p2[1]) * f;
  }
  f = twicearea * 3;
  return [x / f, y / f];
}

export var mock_flatten = flatten;

/**
 * GraphQL base64 encodes its IDs in a form like:
 *   <GraphQL type name>:<ID as an integer>
 * This function takes the type name and ID as inputs and returns
 * a properly-formatted and encoded GraphQL ID that can be used
 * to query for a specific object.
 *
 * @param {string} typeName - The name of the Graphene Type that backs
 *   the collection we are querying (e.g. 'GroundStationServiceType').
 * @param {number} idAsInteger - The number that is the object ID.
 */
export function intToGraphQlId(typeName, idAsInteger) {
  return btoa(`${typeName}:${idAsInteger}`);
}

/**
 * The inverse of intToGraphQlId. It takes a base64-encoded string as
 * input, which should be a GraphQL ID, and returns the database ID as
 * an integer.
 *
 * @param {string} graphQlId - A base64-encoded GraphQL ID
 */
export function graphQlIdToInt(graphQlId) {
  return atob(graphQlId).split(":")[1];
}

export const groundStationTypeChoices = ["AWS Ground", "KSAT-Lite", "Viasat"];

export const groundStationTypeLookup = {
  AWS: groundStationTypeChoices[0],
  KSATLITE: groundStationTypeChoices[1],
  VIASAT: groundStationTypeChoices[2]
};

export function getUniquesFromArray(array, property) {
  return array
    .map(obj => obj[`${property}`])
    .filter((value, index, self) => self.indexOf(value) === index);
}
