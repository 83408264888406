<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col class="feature-title d-flex justify-center mb-5">
        <h3>{{ feature.properties.name }}</h3>
      </v-col>
    </v-row>
    <div class="attribute-wrapper">
      <v-row no-gutters class="attribute-row">
        <v-col cols="5" class="attribute-col"><span>Site Type</span></v-col>
        <v-col :title="rawSiteType">{{ rawSiteType }}</v-col>
      </v-row>
      <v-row no-gutters class="attribute-row">
        <v-col cols="5" class="attribute-col"><span>Country</span></v-col>
        <v-col>{{ feature.properties.country }}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5" class="attribute-col"><span>Geometry</span></v-col>
        <v-col>{{ geometricType }}</v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    feature: {
      required: true,
      type: Object
    }
  },
  mounted() {
    console.log("FEATURE", this.feature);
  },
  computed: {
    rawSiteType() {
      return (
        this.feature.properties.site_type_pretty ||
        this.feature.properties.siteType
      );
    },
    geometricType() {
      return (
        this.feature.properties.geometric_type || this.feature.geometry.type
      );
    }
  }
});
</script>
