import { DateTime, Settings } from "luxon";
import {
  DTFORMAT,
  DTFORMAT_SHORT,
  DTSHORT_TZ,
  TIMEZONE_TRANSLATE
} from "./constants.js";
import store from "../store/index.js";

Settings.defaultZoneName = "utc";

function _titleCase(str, sep) {
  return str
    .split(sep)
    .map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(sep);
}

export function radToDeg(val) {
  return (val * 180) / Math.PI;
}

export function upperCase(str) {
  return str ? str.toUpperCase() : "";
}

export function titleCase(str) {
  if (str) {
    str = str.toLowerCase();
    str = _titleCase(str, "-");
    str = _titleCase(str, " ");
  }
  return str;
}

export function round(num) {
  if (num) {
    num = Math.round(num);
  }
  return num;
}

export function fixed(num, place) {
  return num.toFixed(place);
}

export function secToMin(t) {
  t = Math.floor(t / 60);
  return t;
}

export function secToDHM(t) {
  let d = Math.floor(t / 86400);
  t -= d * 86400;
  let h = Math.floor(t / 3600);
  t -= h * 3600;
  let m = Math.floor(t / 60);

  d = String(d).padStart(2, "0");
  h = String(h).padStart(2, "0");
  m = String(m).padStart(2, "0");

  return `${d}:${h}:${m}`;
}

export function dt(dt, format) {
  if (!dt) {
    return "---";
  }

  if (!format) {
    format = DTFORMAT;
  }

  if (format.toLowerCase() == "short") {
    format = DTFORMAT_SHORT;
  } else if (format.toLowerCase() == "short_tz") {
    format = DTSHORT_TZ;
  }

  if (typeof dt == "string") {
    dt = DateTime.fromISO(dt);
  } else if (dt instanceof Date) {
    dt = DateTime.fromJSDate(dt);
  }

  dt = dt.setZone(TIMEZONE_TRANSLATE[store.state.timezone]);

  return dt.toFormat(format);
}

export function truncate(text, max) {
  if (text && text.length > max) {
    return text.slice(0, max);
  }

  return text;
}

export function eta(ts) {
  var t1 = new Date();
  t1 = t1.getTime() / 1000;
  var pre = "-";
  if (ts > t1) {
    pre = "+";
  }

  var diff = Math.abs(ts - t1);
  var days = parseInt(diff / (60 * 60 * 24));
  diff = diff - days * 60 * 60 * 24;
  var hrs = parseInt(diff / (60 * 60));
  diff = diff - hrs * 60 * 60;
  var min = parseInt(diff / 60);

  return `${pre}${days}d ${hrs}h ${min}m`;
}

export function install_filters(vue) {
  vue.filter("titleCase", titleCase);
  vue.filter("upperCase", upperCase);
  vue.filter("round", round);
  vue.filter("fixed", fixed);
  vue.filter("secToMin", secToMin);
  vue.filter("secToDHM", secToDHM);
  vue.filter("radToDeg", radToDeg);
  vue.filter("dt", dt);
  vue.filter("truncate", truncate);
  vue.filter("eta", eta);
}
