const SitesAdd = () => import("../sites/pages/SitesAdd.vue");
const SiteEdit = () => import("../sites/pages/SiteEdit.vue");
const SiteSetAddMap = () => import("../sites/pages/SiteSetAddMap.vue");
const SiteSetTabular = () => import("../sites/pages/SiteSetTabular.vue");
const SiteSetEdit = () => import("../sites/pages/SiteSetEdit.vue");
const SiteSetAdd = () => import("../sites/pages/SiteSetEdit.vue");
const SitesTabular = () => import("../sites/pages/SitesTabular.vue");
const SitesView = () => import("../sites/pages/SitesView.vue");

export default [
  {
    path: "/sites/add",
    name: "sites-add",
    component: SitesAdd
  },
  {
    path: "/sites/site/edit/:siteId",
    name: "site-edit",
    component: SiteEdit,
    props: true
  },
  {
    path: "/sites/set/add-map",
    name: "set-add-map",
    component: SiteSetAddMap
  },
  {
    path: "/sites/sets",
    name: "set-tabular",
    component: SiteSetTabular
  },
  {
    path: "/sites/set/add",
    name: "set-add",
    component: SiteSetAdd
  },
  {
    path: "/sites/set/edit/:setId",
    name: "set-edit",
    component: SiteSetEdit,
    props: true
  },
  {
    path: "/sites/sites",
    name: "sites-tabular",
    component: SitesTabular
  },
  {
    path: "/sites/sites-map",
    name: "sites-View",
    component: SitesView
  }
];
