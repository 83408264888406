import VueRouter from "vue-router";

import { alert_dialog } from "./widgets/dialogs.js";
import Auth from "./services/auth.js";
import store from "./store/index.js";

import Sites from "./sites/routes.js";
import Scale from "./scale/routes.js";

const Dashboard = () => import("./core/dashboard/dashboard.vue");

const Mission = () => import("./space/mission/Mission.vue");
const MissionWait = () => import("./space/mission/SimWait.vue");

const MissionList = () => import("./space/mission/cog/MissionList.vue");
const MissionEdit = () => import("./space/mission/cog/MissionEdit.vue");

const PassScheduler = () => import("./ground/pages/PassScheduler.vue");
const PassesList = () => import("./ground/pages/PassesList.vue");
const GroundStationsList = () =>
  import("./ground/pages/GroundStationsList.vue");
const GroundStationEditor = () =>
  import("./ground/pages/GroundStationEditor.vue");
const GroundStationServicesList = () =>
  import("./ground/pages/GroundStationServicesList.vue");
const GroundStationServiceEditor = () =>
  import("./ground/pages/GroundStationServiceEditor.vue");
const SatellitesList = () => import("./ground/pages/SatellitesList.vue");
const SatelliteEditor = () => import("./ground/pages/SatelliteEditor.vue");

const PassTracking = () => import("./ground/pages/PassTracking.vue");

const AvailabilityTimeline = () =>
  import("./ground/pages/AvailabilityTimeline.vue");

const Login = () => import("./core/account/login.vue");
const AccountEdit = () => import("./core/account/edit.vue");
const Invite = () => import("./core/account/invite.vue");

const OrgSettings = () => import("./core/config/org_settings.vue");

const CustomCZML = () => import("./misc/CustomViewer.vue");
const DemosList = () => import("./showroom/pages/DemosList.vue");
const DemoViewer = () => import("./showroom/pages/DemoViewer.vue");

const NotLicensed = () => import("./core/config/not_licensed.vue");

const NotFound = {
  template: "<div><h2>Page Not Found: 404</h2></div>"
};

export var Routes = [
  { path: "/missions/new", component: MissionEdit, name: "mission-new" },
  { path: "/missions/edit/:id", component: MissionEdit, name: "mission-edit" },
  { path: "/missions/wait/:ids", component: MissionWait, name: "mission-wait" },
  { path: "/missions/:id/all", component: Mission, name: "mission-all" },
  {
    path: "/missions/:id/schedule",
    component: Mission,
    name: "mission-schedule"
  },
  {
    path: "/missions/:id/opportunities",
    component: Mission,
    name: "mission-opps"
  },
  {
    path: "/missions/:id/tipandcue",
    component: Mission,
    name: "mission-tip"
  },
  {
    path: "/missions/:id/concurrentcollect",
    component: Mission,
    name: "mission-collect"
  },
  { path: "/missions/:id/globe", component: Mission, name: "mission-globe" },
  { path: "/missions/:id/memory", component: Mission, name: "mission-memory" },
  { path: "/missions/", component: MissionList, name: "missions" },

  {
    path: "/ground/passes/list",
    component: PassesList,
    name: "passes-list"
  },
  {
    path: "/ground/passes/passTracking",
    component: PassTracking,
    name: "pass-tracking"
  },
  {
    path: "/ground/passes/availabilityTimeline",
    component: AvailabilityTimeline,
    name: "availability-timeline"
  },
  {
    path: "/ground/passes/schedule",
    component: PassScheduler,
    name: "pass-scheduler"
  },
  {
    path: "/ground/stations/list",
    component: GroundStationsList,
    name: "ground-stations-list"
  },
  {
    path: "/ground/stations/edit/:groundStationId",
    component: GroundStationEditor,
    name: "edit-ground-station",
    props: true
  },
  {
    path: "/ground/stations/add",
    component: GroundStationEditor,
    name: "add-ground-station"
  },
  {
    path: "/ground/services/list",
    component: GroundStationServicesList,
    name: "ground-services-list"
  },
  {
    path: "/ground/services/edit/:groundStationId",
    component: GroundStationServiceEditor,
    name: "edit-ground-service",
    props: true
  },
  {
    path: "/ground/services/add",
    component: GroundStationServiceEditor,
    name: "add-ground-service"
  },
  {
    path: "/ground/satellites/list",
    component: SatellitesList,
    name: "satellites-list"
  },
  {
    path: "/ground/satellites/edit/:satelliteId",
    component: SatelliteEditor,
    name: "edit-satellite",
    props: true
  },
  {
    path: "/ground/satellites/add",
    component: SatelliteEditor,
    name: "add-satellite"
  },

  { path: "/accounts/edit", component: AccountEdit, name: "account-edit" },
  { path: "/invites/accept/:token", component: Invite, name: "invite" },
  { path: "/org/settings", component: OrgSettings, name: "org-settings" },

  { path: "/", component: Dashboard, name: "dashboard" },

  { path: "/login", component: Login, name: "login" },

  { path: "/showroom/demos", component: DemosList, name: "demo-list" },
  { path: "/showroom/viewer", component: DemoViewer, name: "demo-viewer" },

  { path: "/custom", component: CustomCZML, name: "custom" },

  { path: "*", component: NotFound, name: "404" },
  { path: "/not-licensed", component: NotLicensed, name: "not-licensed" }
];

Routes.push(...Sites);
Routes.push(...Scale);

var ropts = {
  routes: Routes,
  mode: "history",
  scrollBehavior: function(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
};

var router = new VueRouter(ropts);

function check_module_paths(to, from, next) {
  let modules = store.state.modules;
  if (modules === null) {
    setTimeout(() => {
      check_module_paths(to, from, next);
    }, 10);
    return;
  }

  //TO DO IMPLEMENT ROUTE GUARD FOR SITES
  if (to.path.startsWith("/ground") && !modules.includes("ground")) {
    next("/not-licensed");
  } else if (
    (to.path.startsWith("/missions") ||
      to.path.startsWith("/opps") ||
      to.path.startsWith("/targets")) &&
    !modules.includes("space")
  ) {
    next("/not-licensed");
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  if (store.state.token) {
    to.query.token = store.state.token;
    store.commit("set_token", null);
    next(to);
    return;
  }

  if (to.query && to.query.token) {
    store.commit("set_nav", false);
    store.commit("set_token", to.query.token);
    next();
    return;
  } else {
    store.commit("set_nav", true);
  }

  var expired = true;
  if (store.state.login_expires != 0) {
    if (store.state.login_expires > Date.now() / 1000 + 5) {
      expired = false;
    }
  }

  if (to.path.startsWith("/login") || to.path.startsWith("/invites")) {
    next();
  } else if (store.state.logged_in && !expired) {
    return check_module_paths(to, from, next);
  } else {
    Auth.is_authed()
      .then(result => {
        if (result.authed) {
          return check_module_paths(to, from, next);
        } else {
          next({ name: "login", query: { next: to.path } });
        }
      })
      .catch(e => {
        alert_dialog("Error", "Error authenticating");
      });
  }
});

export default router;
