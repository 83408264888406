import { api_wrapper } from "../services/api_wrapper.js";

export const getForecastCustomers = api_wrapper(`
query ($first:Int ){
  forecastCustomer(first: $first) {
    edges {
      node {
        name
        id
        rawId
        targetsetSet(first:100){
          edges{
            node{
              name
              id
              rawId
              targets(first:500){
                geojson
                edges{
                  node{
                    name
                    geometricType
                    siteType
                    siteTypePretty
                    country
                    id
                    locationPoint
                    locationPolygon
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`);

export const getForecastCustomerById = api_wrapper(`
query ($first:Int, $id:Float ){
    forecastCustomer(first: $first, id:$id) {
      edges {
        node {
          name
          id
          rawId
          targetsetSet(first:100){
            edges{
              node{
                name
                id
                rawId
                targetsetsiterefs{
                  site{
                    name
                    geometricType
                      siteType
                      siteTypePretty
                      country
                      id
                      rawId
                      locationPoint
                      locationPolygon
                  }
                  priority
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const getTargetSetsByCustomerId = api_wrapper(`
query ($first:Int, $id:Float ){
    forecastCustomer(first: $first, id:$id) {
      edges {
        node {
          name
          id
          rawId
          targetsetSet(first:100){
            edges{
              node{
                name
                id
                rawId
                targets(first:500){
                  geojson
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const forecastCustomerForm = api_wrapper(`
mutation ($id:ID, $name:String!) {
  forecastCustomerForm( input: {
       id:$id
       name: $name
    }) {
    customer{
      name
      rawId
      id
    }
    errors{
      messages
    }
  }
}
`);

export const forecastTargetSetForm = api_wrapper(`
mutation ($customer:ID!, $name:String!,$targets:String!, $id:ID) {
  forecastTargetsetForm( input: {
       id:$id
       customer:$customer
       name: $name
       targets:$targets
    }) {
    targetSet{
      name
    }
    errors{
      messages
    }
  }
}
`);

export const getSiteSets = api_wrapper(`
query (@autodeclare) {
  siteGroups (first:$first){
    edges {
      node {
        id
        name
        sites (first:$first){
          edges{
            node{
              name
              geometricType
              siteType
              siteTypePretty
              country
              id
              rawId
              locationPoint
              locationPolygon
            }
          }
        }
      }
    }
  }
}`);

export const getForecastSatellites = api_wrapper(`
query ($first:Int ){
    forecastSatellites(first: $first) {
      edges {
        node {
          name
          rawId
          catalogId
          tle
          sensors(first:5)
          {
              edges{
                  node{
                      name
                      sensorType
                      id
                      rawId
                  }
              }
          }
        }
      }
    }
  }
`);

export const getForecastConstellations = api_wrapper(`
query ($first:Int ){
    forecastConstellations(first: $first) {
      edges {
        node {
          name
          rawId
          id
          planes
          satellites(first:30)
          {
              edges{
                  node{
                    name
                     tle
                     id
                     rawId
                  }
              }
          }
        }
      }
    }
  }
`);

export const getForecastConstellationById = api_wrapper(`
query ($first:Int,$id:Float ){
    forecastConstellations(first: $first,id:$id) {
      edges {
        node {
          name
          rawId
          id
          planes
          satellites(first:30)
          {
              edges{
                  node{
                    name
                     tle
                     id
                     rawId
                     sensors(first:5)
                     {
                         edges{
                             node{
                                 name
                                 sensorType
                                 id
                                 rawId
                             }
                         }
                     }
                  }
              }
          }
        }
      }
    }
  }
`);

export const forecastConstellationForm = api_wrapper(`
mutation ($id:ID, $name:String!
  $satellites: [ID]!,
  $planes: String!) {
  forecastConstellationForm( input: {
       id:$id
       name: $name
       satellites: $satellites
       planes: $planes
    }) {
    constellation{
      name
    }
    errors{
      messages
    }
  }
}
`);

export const forecastDeleteConstellation = api_wrapper(`
mutation ($constellationId:Int!) {
  forecastDeleteConstellation(constellationId: $constellationId) {
    result
  }
}
`);

export const forecastStudyForm = api_wrapper(`
mutation ($id:ID, $name:String!
  $targetSets: [ID]!,
  $time: String,
  $constellations:[ID]!,
  $groundStations:[ID]!
  ) {
  forecastStudyForm( input: {
       id:$id
       name: $name
       targetSets: $targetSets
       constellations: $constellations
       time: $time
       groundStations: $groundStations
    }) {
    study{
      name
      rawId
      id
      constellations(first:5){
        edges{
          node{
            name
            rawId
          }
        }
      }
      targetSets(first:5){
        edges{
          node{
            name
            rawId
          }
        }
      }
    }
    errors{
      messages
    }
  }
}
`);

export const forecastRunStudy = api_wrapper(`
mutation ($studyId:ID!) {
  forecastRunStudy(studyId: $studyId) {
    result
  }
}
`);

export const forecastStudyRunUpdateTour = api_wrapper(`
mutation ($studyRunId:ID!, $tour:JSONString) {
  forecastStudyRunUpdateTour(studyRunId: $studyRunId, tour:$tour) {
    studyRun{
      tour
    }
  }
}
`);

export const getForecastStudies = api_wrapper(`
query ($first:Int){
    forecastStudy(first: $first) {
      edges {
        node {
          name
          rawId
          id
          time
          latestStudyRun{
            id
            created
            status
            rawId
          }
        }
      }
    }
  }
`);

export const getForecastStudyById = api_wrapper(`
query ($id:Float, $first:Int){
    forecastStudy(id: $id, first:$first) {
      edges {
        node {
          name
          rawId
          id
          time
          constellations(first:5){
            edges{
              node{
                planes
                name
                satellites(first:20){
                  edges{
                    node{
                      name
                      sensors(first:20){
                        edges{
                          node{
                            name
                            sensorType
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          targetSets(first: 10) {
            edges {
              node {
                name
                targetsetsiterefs {
                  id
                  site {
                    name
                  }
                }
              }
            }
          }
          latestStudyRun{
            id
            created
            status
            rawId
            czml
            tour
            outputCollectionOpportunities
            outputSimSummary
            outputTargetInfo
            outputTargetMttx
            targetSets{
              name
              refs{
                site{
                  name
                }
                maxIdealIncidenceAngle
                minIdealIncidenceAngle
                mtta
                mttc
                totalAccesses
                totalCollections
                id
              }
            }
          }

        }
      }
    }
  }
`);

export const getGroundStationServices = api_wrapper(`
query (@autodeclare) {
  groundStationServices(first: $first){
    edges{
      node{
        id
        name
        apiType
        priority
        groundstationSet(first: $first) {
          edges {
            node {
              id
              name
              location
            }
          }
        }
      }
    }
  }
}
`);
