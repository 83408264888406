<template>
  <div class="infobox" v-if="selected">
    <v-card>
      <v-card-title>
        <span class="flex-grow-1">{{ selected.name | upperCase }}</span>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="data">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-if="cart">
                  <td>Lat, Long</td>
                  <td>
                    {{ latitude | numeral("0.[0000]") }}&deg;,
                    {{ longitude | numeral("0.[0000]") }}&deg;
                  </td>
                </tr>
                <tr v-if="cart">
                  <td>Altitude</td>
                  <td>{{ cart.height | numeral("0,0.[0]") }} m</td>
                </tr>
                <tr v-if="displayPasses">
                  <td>Scheduled Passes</td>
                  <td>{{ displayPasses.length }}</td>
                </tr>

                <tr v-for="pass in displayPasses" :key="pass.id">
                  <td>{{ pass.displayName | upperCase }}</td>
                  <td>{{ `${pass.startTime} - ${pass.endTime}` }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { formatDateString } from "../../services/tools.js";

export default {
  name: "PassInfoBox",
  props: ["selected", "deselect", "time", "passes"],
  data() {
    return {
      tle: null,
      last_name: null
    };
  },
  computed: {
    cart() {
      if (this.selected && this.selected.position) {
        var position = this.selected.position.getValue(this.time.currentTime);
        return Cesium.Cartographic.fromCartesian(position);
      }
    },
    latitude() {
      return (this.cart.latitude * 180) / Math.PI;
    },
    longitude() {
      return (this.cart.longitude * 180) / Math.PI;
    },
    displayPasses() {
      let selected = this.selected.name.toLowerCase();
      return this.passes
        .filter(pass => {
          let station = pass.groundStation.name.toLowerCase();
          let satellite = pass.satellite.name.toLowerCase();
          return selected === station || selected === satellite;
        })
        .map(pass => {
          pass.startTime = formatDateString(pass.timerange[0]);
          pass.endTime = formatDateString(pass.timerange[1]);
          let station = pass.groundStation.name.toLowerCase();
          let satellite = pass.satellite.name.toLowerCase();

          if (selected === station) {
            pass.displayName = pass.satellite.name;
          } else if (selected === satellite) {
            pass.displayName = pass.groundStation.name;
          }
          return pass;
        });
    }
  },
  methods: {
    close() {
      this.deselect();
    }
  },
  mounted() {}
};
</script>
<style lang="less">
.infobox {
  position: absolute;
  width: 350px;
  z-index: 10;
  right: 60px;
  top: 20px;

  .v-card__title {
    span {
      font-size: 80%;
    }

    padding-bottom: 0%;
  }

  .label {
    padding-top: 5px;
    display: inline-block;
  }
}
</style>
