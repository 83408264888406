const ConstellationBuilder = () =>
  import("../scale/pages/ConstellationBuilder.vue");
const ConstellationList = () => import("../scale/pages/ConstellationList.vue");
const CustomerBuilder = () => import("../scale/pages/CustomerBuilder.vue");
const CustomerList = () => import("../scale/pages/CustomerList.vue");
const StudyBuilder = () => import("../scale/pages/StudyBuilder.vue");
const StudyList = () => import("../scale/pages/StudyList.vue");
const StudyViewer = () => import("../scale/pages/StudyViewer.vue");

export default [
  {
    path: "/scale/constellation/list",
    name: "constellation-list",
    component: ConstellationList
  },
  {
    path: "/scale/constellation/add",
    name: "constellation-add",
    component: ConstellationBuilder
  },
  {
    path: "/scale/constellation/edit/:constellationId",
    name: "constellation-edit",
    component: ConstellationBuilder,
    props: true
  },
  {
    path: "/scale/customer/list",
    name: "customer-list",
    component: CustomerList
  },
  {
    path: "/scale/customer/add",
    name: "customer-add",
    component: CustomerBuilder
  },
  {
    path: "/scale/customer/edit/:customerId",
    name: "customer-edit",
    component: CustomerBuilder,
    props: true
  },
  {
    path: "/scale/study/add",
    name: "study-add",
    component: StudyBuilder
  },
  {
    path: "/scale/study/list",
    name: "study-list",
    component: StudyList
  },
  {
    path: "/scale/study/:studyId/viewer",
    name: "study-viewer",
    component: StudyViewer,
    props: true
  },
  {
    path: "/scale/study/:studyId/target-statistics",
    name: "study-target-statistics",
    component: StudyViewer,
    props: true
  },
  {
    path: "/scale/study/:studyId/constellation-design",
    name: "study-constellation-design",
    component: StudyViewer,
    props: true
  }
];
