import deepcopy from "deepcopy";

const _EVENT_LABELS = {
  "target-observed": "Observed",
  downlink: "Downlinked"
};

export function EVENT_LABELS() {
  return deepcopy(_EVENT_LABELS);
}

export const DTFORMAT = "LL/dd/yy HH:mm:ss ZZZZ";
export const DTFORMAT_SHORT = "LL/dd HH:mm";
export const DTSHORT_TZ = "LL/dd HH:mm ZZZZ";
export const REQUEST_DTFORMAT = "LL/dd/yyyy HH:mm";

export const TIMEZONES = [
  "UTC",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "Pacific/Guam",
  "Europe/London"
];

export const TIMEZONE_TRANSLATE = {
  UTC: "UTC",
  "US/Alaska": "America/Anchorage",
  "US/Aleutian": "America/Adak",
  "US/Arizona": "America/Phoenix",
  "US/Central": "America/Chicago",
  "US/East-Indiana": "America/Indiana/Indianapolis",
  "US/Eastern": "America/New_York",
  "US/Hawaii": "Pacific/Honolulu",
  "US/Indiana-Starke": "America/Indiana/Knox",
  "US/Michigan": "America/Detroit",
  "US/Mountain": "America/Denver",
  "US/Pacific": "America/Los_Angeles",
  "US/Samoa": "Pacific/Pago_Pago",
  "Pacific/Guam": "Pacific/Guam",
  "Europe/London": "Europe/London"
};

export const BACKEND_TRANSLATE = {
  UTC: "UTC",
  AMERICA_ANCHORAGE: "US/Alaska",
  AMERICA_ADAK: "US/Aleutian",
  AMERICA_PHOENIX: "US/Arizona",
  AMERICA_CHICAGO: "US/Central",
  AMERICA_INDIANA_INDIANAPOLIS: "US/East-Indiana",
  AMERICA_NEW_YORK: "US/Eastern",
  PACIFIC_HONOLULU: "US/Hawaii",
  AMERICA_INDIANA_KNOX: "US/Indiana-Starke",
  AMERICA_DETROIT: "US/Michigan",
  AMERICA_DENVER: "US/Mountain",
  AMERICA_LOS_ANGELES: "US/Pacific",
  PACIFIC_PAGO_PAGO: "US/Samoa",
  PACIFIC_GUAM: "Pacific/Guam",
  EUROPE_LONDON: "Europe/London"
};
