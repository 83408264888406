import { api_wrapper } from "/services/api_wrapper.js";

export const get_demos = api_wrapper(`
query (@autodeclare){
    demos(first:$first) {
      edges {
        node {
          name
          id
          group
          tour
          czmlUrl
          logoUrl
        }
      }
    }
  }
`);

export const get_demo = api_wrapper(`
query ($id: ID) {
  demos (first: 1, id: $id){
    edges{
      node{
        name
        group
        collectionOpps
        czmlUrl
        logoUrl
        tour
      }
    }
  }
}`);

export const edit_demo = api_wrapper(`
mutation ($id:ID!, $tour: JSONString, $name:String, $group:String) {
  editDemo(id: $id, tour:$tour, name:$name, group:$group) {
    wasSuccessful
  }
}
`);
