import { api_wrapper } from "../../services/api_wrapper.js";

export const get_ground_station_services = api_wrapper(`
query (@autodeclare) {
  groundStationServices(first: $perpage){
    edges{
      node{
        id
        name
        apiType
        priority
        groundstationSet(first: $perpage) {
          edges {
            node {
              id
              name
              location
            }
          }
        }
      }
    }
  }
}
`);

export const get_ground_stations = api_wrapper(`
query (@autodeclare) {
  groundStations(first: $perpage){
  edges{
    node{
      id
      name
      groundStationService{
      name
        id
        apiType
    }
    }
  }
}
}
`);

export const get_ground_station_service_by_id = api_wrapper(`
query (@autodeclare) {
  groundStationServices(first: $perpage, id: $id) {
    edges {
      node {
        id
        name
        apiType
      }
    }
  }
}
`);

export const get_ground_station_by_id = api_wrapper(`
query (@autodeclare) {
  groundStations(first: $perpage, id: $id) {
    edges {
      node {
        id
        name
        location
        groundStationService{
          name
            id
            apiType
        }
      }
    }
  }
}
`);

export const add_ground_station_service = api_wrapper(`
mutation (@autodeclare) {
  addGroundStationService(
    input: {
      name: $name
      apiKey: $apiKey
      apiType: $apiType
    }
  ) {
    groundStationService {
      name
      apiType
      id
    }
    errors {
      messages
    }
  }
}
`);

export const delete_ground_station_service = api_wrapper(`
mutation (@autodeclare) {
  deleteGroundStationService(id: $id) {
    wasSuccessful
  }
}
`);

export const update_ground_station_service = api_wrapper(`
mutation (@autodeclare) {
  updateGroundStationService(id: $id, name: $name, apiKey: $apiKey, apiType: $apiType) {
    wasSuccessful
  }
}
`);

export const update_ground_station_service_priorities = api_wrapper(`
mutation ($priorityData: [GroundStationServicePriorityUpdateType]) {
  updateGroundStationServicePriorities(priorityData: $priorityData) {
    groundStationServices {
      id
      name
      apiType
      priority
    }
  }
}
`);

export const add_ground_station = api_wrapper(`
mutation ($name: String!,
  $groundStationService: ID!,
  $location: String) {
  addGroundStation(
    input: {
      name: $name
      groundStationService: $groundStationService
      location: $location
    }
  ) {
    groundStation{
      name
      location
    }
    errors {
      messages
    }
  }
}
`);

export const update_ground_station = api_wrapper(`
mutation ($id:ID, $name: String,
  $groundStationServiceId: String,
  $location: String) {
  updateGroundStation(id: $id, name: $name, location: $location, groundStationServiceId: $groundStationServiceId) {
    wasSuccessful
  }
}
`);

export const delete_ground_station = api_wrapper(`
mutation (@autodeclare) {
  deleteGroundStation(id: $id) {
    wasSuccessful
  }
}
`);

export const get_satellites = api_wrapper(`
query (@autodeclare) {
  satellites(first: $perpage){
    edges{
      node{
        id
        name
        arn
        isAutomaticSchedulingDisabled
        automaticSchedulingStartTime
        automaticSchedulingEndTime
        catalogId
        centerFrequency
        currentTle
        externalId
        passSet(first: $perpage) {
          edges {
            node {
              timerange
            }
          }
        }
        supportedGroundStationServices
      }
    }
  }
}
`);

export const add_satellite = api_wrapper(`
mutation (
  $automaticSchedulingStartTime: String,
  $automaticSchedulingEndTime: String,
  $automaticSchedulingGroundStations: [String],
  $automaticSchedulingGroundStationServices: [String],
  $linkBudgetForMonday: Int,
  $linkBudgetForTuesday: Int,
  $linkBudgetForWednesday:Int,
  $linkBudgetForThursday:Int,
  $linkBudgetForFriday:Int,
  $linkBudgetForSaturday:Int,
  $linkBudgetForSunday:Int,
  $automaticSchedulingTimeZone: String,
  $catalogId: String!,
  $centerFrequency: Decimal,
  $currentTle: String!,
  $externalId: String!,
  $isAutomaticSchedulingDisabled: Boolean
  $name: String!,
) {
  addSatellite(
    automaticSchedulingStartTime: $automaticSchedulingStartTime,
    automaticSchedulingEndTime: $automaticSchedulingEndTime,
    automaticSchedulingGroundStations: $automaticSchedulingGroundStations,
    automaticSchedulingGroundStationServices: $automaticSchedulingGroundStationServices,
    linkBudgetForMonday: $linkBudgetForMonday,
    linkBudgetForTuesday:$linkBudgetForTuesday,
    linkBudgetForWednesday:$linkBudgetForWednesday,
    linkBudgetForThursday:$linkBudgetForThursday,
    linkBudgetForFriday:$linkBudgetForFriday,
    linkBudgetForSaturday:$linkBudgetForSaturday,
    linkBudgetForSunday:$linkBudgetForSunday,
    automaticSchedulingTimeZone: $automaticSchedulingTimeZone,
    catalogId: $catalogId,
    centerFrequency: $centerFrequency,
    currentTle: $currentTle,
    externalId: $externalId,
    isAutomaticSchedulingDisabled: $isAutomaticSchedulingDisabled
    name: $name,
  ) {
    satellite {
      name
    }
  }
}
`);

export const delete_satellite = api_wrapper(`
mutation (@autodeclare) {
  deleteSatellite(id: $id) {
    wasSuccessful
  }
}
`);

export const update_satellite = api_wrapper(`
mutation (
  $id: ID,
  $automaticSchedulingStartTime: String,
  $automaticSchedulingEndTime: String,
  $automaticSchedulingGroundStations: [String],
  $automaticSchedulingGroundStationServices: [String],
  $linkBudgetForMonday: Int,
  $linkBudgetForTuesday: Int,
  $linkBudgetForWednesday:Int,
  $linkBudgetForThursday:Int,
  $linkBudgetForFriday:Int,
  $linkBudgetForSaturday:Int,
  $linkBudgetForSunday:Int,
  $automaticSchedulingTimeZone: String,
  $catalogId: String,
  $centerFrequency: Decimal,
  $currentTle: String,
  $externalId: String,
  $name: String,
  $isAutomaticSchedulingDisabled: Boolean
) {
  updateSatellite(
    id: $id,
    automaticSchedulingStartTime: $automaticSchedulingStartTime,
    automaticSchedulingEndTime: $automaticSchedulingEndTime,
    automaticSchedulingGroundStations: $automaticSchedulingGroundStations,
    automaticSchedulingGroundStationServices: $automaticSchedulingGroundStationServices,
    linkBudgetForMonday: $linkBudgetForMonday,
    linkBudgetForTuesday:$linkBudgetForTuesday,
    linkBudgetForWednesday:$linkBudgetForWednesday,
    linkBudgetForThursday:$linkBudgetForThursday,
    linkBudgetForFriday:$linkBudgetForFriday,
    linkBudgetForSaturday:$linkBudgetForSaturday,
    linkBudgetForSunday:$linkBudgetForSunday,
    automaticSchedulingTimeZone: $automaticSchedulingTimeZone,
    catalogId: $catalogId,
    centerFrequency: $centerFrequency,
    currentTle: $currentTle,
    externalId: $externalId,
    name: $name,
    isAutomaticSchedulingDisabled: $isAutomaticSchedulingDisabled
  ) {
    wasSuccessful
  }
}
`);

export const get_satellite_by_id = api_wrapper(`
query (@autodeclare) {
  satellites(first: $perpage, id: $id){
    edges{
      node{
        id
        name
        automaticSchedulingEndTime
        automaticSchedulingStartTime
        automaticSchedulingTimeZone
        isAutomaticSchedulingDisabled
        linkBudgetForMonday
        linkBudgetForTuesday
        linkBudgetForWednesday
        linkBudgetForThursday
        linkBudgetForFriday
        linkBudgetForSaturday
        linkBudgetForSunday
        automaticSchedulingGroundStations(first: 10){
          edges{
           node{
             name
             id
             groundStationService {
               id
               name
             }
           }
         }
       }
       automaticSchedulingGroundStationServices(first: 10){
        edges{
         node{
           name
           id
         }
       }
     }
        catalogId
        centerFrequency
        currentTle
        externalId
      }
    }
  }
}
`);

export const get_passes = api_wrapper(`
query ($perpage: Int, $startTime: String, $endTime: String ) {
  passes(first: $perpage, startTime: $startTime, endTime: $endTime) {
    edges {
      node {
        id
        timerange
        groundStation {
          id
          location
          name
        }
        groundStationService {
          apiType
          id
          name
        }
        satellite {
          id
          name
        }
        status
      }
    }
  }
}
`);

export const get_satellite_czml = api_wrapper(`
query ($ids: [String], $startTime:DateTime,$endTime:DateTime) {
  satelliteCzml(ids: $ids, startTime:$startTime,endTime:$endTime)
}
`);

export const cancel_pass = api_wrapper(`
mutation ($id: ID) {
  cancelPass(
    id: $id,
  ) {
    response
  }
}
`);

export const schedule_pass = api_wrapper(`
mutation ($endTime: DateTime, $satellite: String, $groundStationId: String, $startTime: DateTime) {
  schedulePass(
    endTime: $endTime, 
    satelliteId: $satellite,
    groundStationId: $groundStationId,
    startTime: $startTime
  ) {
    response
  }
}
`);

export const list_available_passes = api_wrapper(`
query ($groundStationIds: [String], $satelliteId: String) {
  listAvailablePasses(groundStationIds: $groundStationIds, satelliteId: $satelliteId)
}
`);
