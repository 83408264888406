import store from "../store/index.js";

import Alert from "./alert-dialog.vue";
import Confirm from "./confirm-dialog.vue";
import Snack from "./snack-message.vue";

export function alert_dialog(title, message, callback) {
  store.commit("set_dialog", {
    mixins: [Alert],
    data() {
      return { title, message };
    },
    methods: {
      callback() {
        if (callback) {
          callback();
        }
      }
    }
  });
}

export function confirm_dialog(message) {
  return new Promise((resolve, reject) => {
    store.commit("set_dialog", {
      mixins: [Confirm],
      data() {
        return { message, resolve, reject };
      }
    });
  });
}

export function snack(message) {
  store.commit("set_snack", {
    mixins: [Snack],
    data() {
      return { message };
    }
  });
}
